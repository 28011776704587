import React from 'react';

import { format } from 'date-fns'

import styled from 'styled-components';

import formatCurrency from '../helpers/formatCurrency';


const TableNew = ({ data = [] }) => {
  return (
    <Container>
      <div>
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Cliente</th>
              <th>Data</th>
              <th>Pedido</th>
              <th style={{ minWidth: '170px' }}>Forma de pagamento</th>
              <th>Valor</th>
              {data[0]?.length === 7 && (
                <th style={{ minWidth: '130px' }}>Valor da busca</th>
              )}
            </tr>
          </thead>

          <tbody>
            {data.map((order) => (
              <tr key={order[0]}>
                <td className="font-style">{order[0]}</td>
                <td className="font-style">{order[1]}</td>
                <td>{format(new Date(order[2] + "T00:00:00"), 'dd/MM/yy')}</td>
                <td style={{ maxWidth: '350px' }}>{order[3]}</td>
                <td>{order[4]}</td>
                <td>{formatCurrency(order[5])}</td>
                {order.length === 7 && (
                  <td>{formatCurrency(order[6])}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  div {
    overflow:auto;
    height: calc(100vh - 292px);;
  
    table {
      width: 100%;
      position: relative;
      border-collapse: collapse; 

      thead {
        height: 50px;
        border-radius: 3px;
        
        th {
          background: #D18B00;
          position: sticky;
          top: 0; 
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);

          color: #FFF;
          padding: 16px 10px;
          font-size: 18px;
          font-weight: 700;
          line-height: 21.6px;
          text-align: left;
        }
      }

      tbody {
        tr {
          -webkit-box-shadow: 0px 0px 3px -2px #000000; 
          box-shadow: 0px 0px 3px -2px #000000;}

        tr:nth-child(even) {
          background-color: #F7F7F7;
        }
        
        td {
          color: #21201F;
          padding: 16px 10px;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          border: 0;

          &.font-style {
            font-weight: 700;
          }
        }
      }
    }
  }
`;

export default TableNew;