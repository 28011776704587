import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding: ${(props) => (props.hasPadding ? `24px` : `0px`)};
  position: relative;

  max-height: calc(100vh - 135px);
  overflow-x: hidden;

  height: ${(props) => (props.full ? `calc(85vh - 120px)` : `auto`)};

  .disabled {
    cursor: default;
  }
`;

export const ContainerButton = styled.div`
  background-color: transparent;
  border: none;
  color: ${(props) => (props.isDisabled ? `#CCCCCC` : `#262524`)};
  cursor: ${(props) => (props.isDisabled ? `default` : `pointer`)};
  font-size: 20px;
  font-weight: 400;
  font-family: "ArialMT";
  line-height: 24px;
  outline: none;
  padding: 24px 40px 24px 24px;
  position: relative;
  text-align: left;
  width: 100%;

  opacity: ${(props) => (props.inactive ? `0.4` : `1`)};

  ${(props) =>
    props.isDisabled
      ? ``
      : `&:hover{
            opacity: 0.7;
            transition: opacity 0.3s;
        }`}

  &:after {
    content: "";
    display: block;
    height: 2px;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    width: calc(100% - 64px);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const ContainerSection = styled.div`
  padding: 18px 0;
  position: relative;

  ${(props) =>
    props.noPaddingTop
      ? `
        padding-top: 0;`
      : ``}

  ${(props) =>
    props.hasDivider
      ? `
        &:after{
            content: '';
            display: block;
            height: 2px;
            background-color: #CCCCCC;
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    `
      : ``}
`;

export const Scrollable = styled.div`
  height: calc(100vh - 152px);
  overflow: auto;
  padding: 0 24px;
`;

export const ContainerSwitch = styled.div`
  padding: 20px 24px;
  position: relative;

  ${(props) =>
    props.noBorder
      ? ``
      : `
            &:after{
                content: '';
                display: block;
                height: 2px;
                background-color: #CCCCCC;
                position: absolute;
                bottom: 0;
                width: calc(100% - 48px);
            }
        `}
`;
