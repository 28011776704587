function dateIsValid(date) {
	const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
	const arrayDate = date.split("/");
  const newArrayDate = arrayDate.map(value => (
    Number(value)
  ));
	if (date.search(ExpReg) === -1){
		return false;
	}
	else if (((newArrayDate[1] === 4)||(newArrayDate[1] === 6)||(newArrayDate[1] === 9)||(newArrayDate[1] === 11))&&(newArrayDate[0]>30))
		return false;
	else if (newArrayDate[1] === 2) {
		if ((newArrayDate[0] > 28)&&((newArrayDate[2]%4)!== 0))
			return false;
		if ((newArrayDate[0] > 29))
			return false;
	}
	return true;
}

export default dateIsValid;