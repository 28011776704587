import { createGlobalStyle } from 'styled-components';

import variables from './variables';
import Proxima from '../fonts/ProximaNova-Regular.otf';
import ArialMT from '../fonts/ArialMT.woff';
import ArialMTLight from '../fonts/ArialMTLight.woff';
import ArialMTBold from '../fonts/ArialMTBold.woff';


const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: Proxima;
        src: url('${Proxima}') format('opentype');
    }

    @font-face {
        font-family: 'ArialMT';
        src: url('${ArialMT}') format('opentype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'ArialMT';
        src: url('${ArialMTLight}') format('opentype');
        font-weight: 300;
    }

    @font-face {
        font-family: 'ArialMT';
        src: url('${ArialMTBold}') format('opentype');
        font-weight: 700;
    }

    @keyframes card-border-animation{
        0%{
            border: 1px solid #D18B00;
        }

        100%{
            border: 1px solid #E3E3E3;
        }
    }


    html, body{
        height: 100v;
        background: ${variables.body.background};
        font-family: ${variables.body.fontFamily};
        overflow: hidden;
    }

    * {
        box-sizing: border-box;
    }

    #root, .app, main{
        height: 100vh;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
       
    ::-webkit-scrollbar-track {
        background: #DADADA;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #9E9E9E; 
    }

    :focus{
        outline: none;
    }

    input:not(.number)::-webkit-outer-spin-button,
    input:not(.number)::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export default GlobalStyles;