import styled from 'styled-components'
import Select from 'react-dropdown-select'

const Dropdown = styled(Select)`
    &.react-dropdown-select{
        padding-left: 12px;
        min-height: 42px;
        border: 1px solid #979797;
        border-radius: 0;
        outline: none;
        box-shadow: 0;

        :hover, :focus{
            border: 1px solid #979797;
            box-shadow: none;
        }

        .react-dropdown-select-content{
            color: #858585;
            font-family: ArialMT;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
        }

        .react-dropdown-select-input {
            color: #858585;
            font-family: ArialMT;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            margin-left: 0;
            outline: 0;
        }

        .react-dropdown-select-dropdown-handle{
            svg{
                width: 28px;
                height: 28px;
                fill: #F29448;
            }
        }
    }

    .react-dropdown-select-dropdown {
        border: none;
        padding: 4px 0 8px;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        border: 1px solid #979797;
        
        :hover, :focus{
            border: 1px solid #979797;
            box-shadow: none;
        }

        .react-dropdown-select-item {
            color: #858585;
            font-family: ArialMT;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            padding: 12px;
    
            :hover {
                background-color: #f7f7f7;
                color: #858585;
            }
        }

        .react-dropdown-select-item-selected{
            background-color: #f7f7f7;
            font-weight: 700;
            color: #858585;
        }
    }
`

export default Dropdown