import React from "react";
import styled from "styled-components";
import {Modal, ModalContainer, ModalTitle, ModalClose} from "./Modal";
import {Button} from "./Button";
import {ReactComponent as Close} from "../assets/svg/close.svg";
import Axios from "../Axios";
import Checkbox from "./Checkbox";
import ModalCreditCards from "./ModalCreditCards";

const ModalPaymentBlock = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  width: 425px;

  h1 {
    margin-bottom: 24px;
  }

  .error {
  }
`;

const CashPayment = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 32px;
  flex-direction: column;

  p {
    color: #838383;
    font-family: "ArialMT";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding-right: 10px;
  }

  .input__container {
    position: relative;
    display: inline-block;
  }

  .block {
    display: block;
    width: 100%;

    p {
      padding-top: 5px;
      display: inline-block;
    }
  }

  .troco {
    padding-top: 10px;
    text-align: right;
  }

  span {
    position: absolute;
    z-index: 2;
    top: 8px;
    left: 8px;
    color: #838383;
    font-family: "ArialMT";
    font-size: 14px;
  }

  button {
    background-color: #d8d8d8;
    border: 1px solid #979797;
    width: 34px;
    height: 30px;
    color: #838383;
    font-family: "ArialMT";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  input {
    width: 105px;
    padding-left: 30px;
    border: 1px solid #979797;
    height: 30px;
    position: relative;
    top: 0px;
    outline: 0;
    color: #838383;

    &:before {
      content: "R$";
    }
  }
`;

const Payment = styled.div`
  position: relative;

  h1 {
    margin-bottom: 12px;
  }

  .checkbox__container {
    padding: 12px 0;

    label {
      color: #262524;
      font-family: ArialMT;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

class ModalPayment extends React.Component {
  state = {
    error: false,
    cashPayment: false,
    isCreditCard: false,
    creditCard: "",
    modalCards: false,
    price: 0.0,
    change: 0,
    exchange: "0,00",
  };

  async componentDidMount() {
    this.setState({
      price: this.props.order.deliveryPrice,
      cashPayment: this.props.order.paymentMode === "cash",
      isCreditCard: this.props.order.paymentMode !== "cash",
      creditCard: this.props.order.flag,
      exchange: await this.getExchange(),
    });
  }

  activeCashPayment() {
    if (!this.state.cashPayment) {
      this.setState({
        cashPayment: true,
        isCreditCard: false,
        exchange: "0,00",
        creditCard: "",
      });
      return;
    }

    this.setState({cashPayment: false});
  }

  activeCreditCard() {
    if (this.state.isCreditCard) {
      this.openModalCards();
      return;
    }

    this.setState(
      {cashPayment: false, isCreditCard: true, creditCard: ""},
      () => this.openModalCards()
    );
  }

  openModalCards() {
    this.setState({modalCards: true});
  }

  closeModalCards() {
    this.setState({modalCards: false}, () => console.log(this.state));
  }

  onChangeCreditCard(value) {
    this.setState({creditCard: value});
  }

  async getExchange() {
    let exchange = 0;
    await Axios.get(`/orders/${this.props.order.id}`).then((response) => {
      exchange = response.data.change;
    });
    return exchange;
  }

  async savePayment() {
    let obj = {
      isDelivery: this.props.order.isDelivery,
      address_id: this.props.address_id,
      change: parseInt(this.state.exchange),
      paymentMode: this.state.cashPayment ? "cash" : "physical_card",
    };

    if (this.state.isCreditCard) obj.flag = this.state.creditCard;

    await Axios.put(`orders/${this.props.order.id}`, obj)
      .then((req) => {
        this.props.onClose(obj);
      })
      .catch((req) => {
        if (req.response && req.response.status === 400)
          this.setState({error: req.response.data});
      });
  }

  calcExchange() {
    let exchangeFor = document.querySelector("#trocoCalc");

    if (exchangeFor)
      if (!exchangeFor || exchangeFor.value === "") {
        this.setState({exchange: "0,00"});
        return;
      }

    exchangeFor = exchangeFor.value;

    let exchange = parseFloat(exchangeFor) - parseFloat(this.state.price);

    if (exchange < 0) {
      this.setState({exchange: "0,00"});
      return;
    }

    this.setState({
      exchange: parseFloat(exchange).toFixed(2).replace(".", ","),
    });
  }
  
  handleClick = (e) => {
	if (e.target !== e.currentTarget) return;
	this.props.onClose();
  };	

  render() {
    return (
      <Modal className onClick={this.handleClick}>
        <ModalContainer>
          <ModalClose href="#" onClick={this.props.onClose}>
            <Close></Close>
          </ModalClose>
          <ModalPaymentBlock>
            <form name="payment-method">
              <Payment>
                <ModalTitle>
                  {this.props.edit ? `Editar` : `Adicionar`} meio de pagamento
                </ModalTitle>
                <Checkbox
                  defaultChecked={this.props.order.paymentMode === "cash"}
                  onClick={this.activeCashPayment.bind(this)}
                  id="cash"
                  name="Pagamento em dinheiro"
                  type="radio"
                  inputName="payment-type"
                ></Checkbox>
                {this.state.cashPayment ? (
                  <CashPayment>
                    <div className="block">
                      <p>Troco para:</p>
                      <div className="input__container">
                        <span>R$</span>
                        <input
                          onChange={this.calcExchange.bind(this)}
                          id="trocoCalc"
                        ></input>
                      </div>
                    </div>
                    {this.state.cashPayment ? (
                      <>
                        <p className="troco">
                          Total do pedido: R${" "}
                          {parseFloat(this.state.price)
                            .toFixed(2)
                            .replace(".", ",")}
                        </p>
                        <p className="troco">Troco: R$ {this.state.exchange}</p>
                      </>
                    ) : (
                      ``
                    )}
                  </CashPayment>
                ) : (
                  ``
                )}
                <Checkbox
                  defaultChecked={this.props.order.paymentMode !== "cash"}
                  onClick={this.activeCreditCard.bind(this)}
                  id="card"
                  name="Máquina de cartão"
                  type="radio"
                  inputName="payment-type"
                ></Checkbox>
              </Payment>
            </form>

            {this.state.error !== false ? (
              <p className="error">{this.state.error}</p>
            ) : (
              ``
            )}

            <Button onClick={this.savePayment.bind(this)}>SALVAR</Button>
          </ModalPaymentBlock>
        </ModalContainer>
        {this.state.modalCards && (
          <ModalCreditCards
            close={this.closeModalCards.bind(this)}
            onChange={this.onChangeCreditCard.bind(this)}
            active={this.state.creditCard}
          />
        )}
      </Modal>
    );
  }
}

export default ModalPayment;
