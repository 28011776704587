import React from "react";
import styled from "styled-components";
import { Modal, ModalTitle } from "./Modal";
import { Button } from "./Button";

const ModalDetailsBlock = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  min-width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  text-align: center;

  h1 {
    color: #414141;
    font-family: Arial;
    font-size: 26px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 43px;
  }

  p {
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    margin: 15px 0;
    color: #424242;

    strong {
      font-weight: 700;
    }

    &.last {
      margin-bottom: 24px;
    }
  }

  .btn {
    border-radius: 4px;
    width: 100%;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    padding: 17px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    background: #eeba1a;
    color: #000;
    margin-bottom: 15px;
    cursor: pointer;
    border: none;

    &-transparent {
      border: 2px solid #d18b00;
      color: #d18b00;
      background: transparent;
    }
  }
`;

const ModalContainer = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  padding: 32px;
  max-width: 449px;
  width: 100%;
  border-radius: 4px;
  justify-content: center;
`;

class ModalConfirmedIngredient extends React.Component {
  handleClick = (e) => {
    if (e.target !== e.currentTarget) return;
    this.props.close();
  };

  render() {
    return (
      <Modal className="modal-order-details" onClick={this.handleClick}>
        <ModalContainer>
          <ModalDetailsBlock>
            <ModalTitle>Por favor, confirme o dados do ingrediente</ModalTitle>
            <p>
              <strong>Ingrediente:</strong> {this.props.ingredient}
            </p>
            <p>
              <strong>Preço:</strong> {this.props.price}
            </p>
            {this.props.quantity && (
              <p className="last">
                <strong>Quantidade:</strong> {this.props.quantity}
              </p>
            )}
            {this.props.portions && (
              <p className="last">
                <strong>Fritas Especiais</strong>
              </p>
            )}
            <Button
              type="button"
              onClick={this.props.close}
              transparent={true}
              noMargin={true}
            >
              VOLTAR PARA O CADASTRO
            </Button>
            <Button
              type="button"
              onClick={(e) =>
                this.props.save(
                  e,
                  this.props.ingredient,
                  this.props.price,
                  this.props.quantity,
                  this.props.portions
                )
              }
              margin={`12px 0 0`}
            >
              CONFIRMAR INGREDIENTE
            </Button>
          </ModalDetailsBlock>
        </ModalContainer>
      </Modal>
    );
  }
}

export default ModalConfirmedIngredient;
