import axios from "axios";
import { authHeader } from "./helpers/authHeader";

const Axios = axios.create({
  baseURL: "https://api-delivery.sanatalanches.com.br/",
  timeout: 30000,
  headers: authHeader(),
});

export default Axios;
