import React from "react";
import styled from "styled-components";
import { Modal, ModalTitle, ModalClose } from "./Modal";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { printProduct } from "../helpers/nfc";

import getProductByID from "../helpers/getProductByID";
import { NavLink, Link } from "react-router-dom";
import Axios from "../Axios";
import AlertModal from "./AlertModal";

const ModalDetailsBlock = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  min-width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 564px;

  h1 {
    margin-bottom: 24px;
    font-family: Proxima;
    font-size: 26px;
    font-weight: 700;
    line-height: 29px;
  }

  p {
    color: #696969;
    font-family: Proxima;
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .info__delivery {
    padding-bottom: 38px;
    p {
      color: #979797;
      text-align: left;
      margin-bottom: 0;
      line-height: 22px;
      margin-bottom: 4px;

      &.tel {
        margin-top: 8px;
      }
    }
  }

  .info__payment p {
    color: #979797;
  }

  .info__observations {
    margin-top: 38px;

    > div {
      max-height: 200px;
      overflow-y: auto;
      word-break: break-all;
    }

    p {
      color: #979797;
    }
  }

  h4 {
    color: #515151;
    font-family: Proxima;
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 8px;
  }
`;

const ColHalf = styled.aside`
  width: 50%;
`;
const Row = styled.div`
  width: 100%;
  display: flex;

  ${ColHalf} {
    &:first-child {
      margin-right: 24px;
    }
    &:last-child {
      margin-left: 24px;
    }
  }
`;

const ModalContainer = styled.div`
  background-color: #fff;
  display: flex;
  position: relative;
  display: inline-block;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Orders = styled.div`
  background-color: #f7f7f7;
  border: 1px solid #979797;
  width: 100%;
  height: calc(100% + 30px);
  padding: 31px;
  font-family: Proxima;
  margin-top: -30px;
  max-height: 530px;
  overflow-y: auto;

  article {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .leftSide {
      display: inline-flex;
      flex-direction: column;
      width: calc(100% - 100px);
    }

    .rightSide {
      text-align: right;
      width: 100px;
    }

    .ingredients {
      padding-bottom: 8px;
    }

    strong {
      font-weight: 700;
    }

    h3 {
      color: #414141;
      font-family: "ArialMT";
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      margin-bottom: 8px;
    }

    p {
      color: #414141;
      font-family: "ArialMT";
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const ConfirmProduction = styled.button`
  margin-top: 10px;
  border: none;
  background: #d18b00;
  color: white;
  padding: 11px 18px;
  font-family: Proxima;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  cursor: pointer;
`;

const OrderInfo = styled.div`
  min-height: 500px;
  position: relative;

  strong {
    color: #000;
  }

  .btn {
    width: 72%;
    font-family: Proxima;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    padding: 10px 17px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    background: #eeba1a;
    color: #000;
    margin: 5px 0;
    cursor: pointer;
    border: none;

    &-transparent {
      border: 2px solid #d18b00;
      color: #d18b00;
      background: transparent;
    }
  }
`;

const ButtonAction = styled.button`
  background: transparent;
  border: none;
  color: #5aad22;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin: 8px 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  outline: 0;

  &.back-status {
    margin-top: 12px;
  }

  &:hover {
    opacity: 0.9;
    transition: 0.3s all;
  }
`;
const Ingredients = styled.span`
  background-color: ${(props) => (props.extra ? "#0CB96C" : "#D1004D")};
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  padding: 3px;
  margin: 2px;
  display: inline-block;
`;

const LinkAction = styled(Link)`
  background: transparent;
  border: none;
  color: #5aad22;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin: 8px 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  outline: 0;
  text-decoration: none;

  &.back-status {
    margin-top: 12px;
  }

  &:hover {
    opacity: 0.9;
    transition: 0.3s all;
  }
`;
class ModalOrderDetails extends React.Component {
  state = {
    isAlertModalOpened: false,
    alertModalTitle: "",
  };

  getStatus() {
    let status;

    switch (this.props.order.status) {
      case "pending":
        status = "Pedido realizado";
        break;
      case "doing":
        status = "Pedido em produção";
        break;
      case "ready":
        status = "Pedido confirmado";
        break;
      case "delivering":
        status = "Pedido em rota de entrega";
        break;
      case "delivered":
        status = "Pedido entregue";
        break;
      default:
        status = "Status não encontrado";
        break;
    }

    return status;
  }

  confirmCancelOrder() {
    window.confirm("Você tem certeza que deseja cancelar este pedido?");
  }

  openModalCancel() {
    this.props.openModalCancel();
  }

  moveOrder(id, status) {
    this.props.onClose();
    this.props.onClickMoveOrder(id, status);
  }

  openModalObs(obj) {
    this.props.openModalObs(obj);
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) this.props.onClose();
    });
  }

  handleClick = (e) => {
    if (e.target !== e.currentTarget) return;
    this.props.onClose();
  };

  render() {
    return (
      <Modal className="modal-order-details" onClick={this.handleClick}>
        <ModalContainer>
          <ModalClose href="#" onClick={this.props.onClose}>
            <Close></Close>
          </ModalClose>
          <ModalDetailsBlock>
            <ModalTitle>#{this.props.order.id}</ModalTitle>

            <Row>
              <ColHalf>
                <Row>
                  <ColHalf>
                    <OrderInfo>
                      <p>
                        Nome: <strong>{this.props.order.name}</strong>
                      </p>
                      {this.props.order.cpfCnpj && (
                        <p>
                          Cpf/Cnpj: <strong>{this.props.order.cpfCnpj}</strong>
                        </p>
                      )}
                      <p>
                        Realizado às:{" "}
                        <strong>
                          {new Date(
                            this.props.order.createdAt
                          ).toLocaleTimeString(undefined, { hour: "2-digit" })}
                          h
                          {new Date(
                            this.props.order.createdAt
                          ).toLocaleTimeString(undefined, {
                            minute: "2-digit",
                          })}
                        </strong>
                      </p>
                      <p>
                        Status: <strong>{this.getStatus()}</strong>
                      </p>
                      {this.props.order.deliveryTime ? (
                        <p>
                          Tempo de entrega:{" "}
                          <strong>{this.props.order.deliveryTime}</strong>
                        </p>
                      ) : (
                        ``
                      )}

                      <div className="actions">
                        {this.props.order.status === "pending" ? (
                          <ConfirmProduction
                            onClick={() =>
                              this.moveOrder(
                                this.props.order.id,
                                this.props.order.status
                              )
                            }
                          >
                            Confirmar Produção
                          </ConfirmProduction>
                        ) : (
                          ``
                        )}
                        {this.props.order.status === "doing" ? (
                          <ConfirmProduction
                            onClick={() =>
                              this.moveOrder(
                                this.props.order.id,
                                this.props.order.status
                              )
                            }
                          >
                            Pedido Pronto
                          </ConfirmProduction>
                        ) : (
                          ``
                        )}
                        {this.props.order.status === "ready" ? (
                          <ConfirmProduction
                            onClick={() =>
                              this.moveOrder(
                                this.props.order.id,
                                this.props.order.status
                              )
                            }
                          >
                            Em rota de entrega
                          </ConfirmProduction>
                        ) : (
                          ``
                        )}
                        {this.props.order.status === "delivering" ? (
                          <ConfirmProduction
                            onClick={() =>
                              this.moveOrder(
                                this.props.order.id,
                                this.props.order.status
                              )
                            }
                          >
                            Confirmar Entrega
                          </ConfirmProduction>
                        ) : (
                          ``
                        )}
                        {/* <ButtonAction className="back-status">Pedido em preparação</ButtonAction> */}
                        <div
                          className="btn"
                          style={{
                            position: "absolute",
                            color: "white",
                            bottom: "50px",
                            left: "0",
                            backgroundColor: "rgba(232,34,45,1)",
                            border: "2px solid rgba(232,34,45,1)",
                            padding: "10px",
                          }}
                          onClick={async () => {
                            try {
                              const response = await Axios.get(
                                `/cancelar-nota-fiscal/${this.props.order.id}`
                              );
                              if (response.status === 200)
                                this.setState({
                                  isAlertModalOpened: true,
                                  alertModalTitle: "Nota fiscal cancelada",
                                });
                            } catch (e) {
                              console.error(e);
                              this.setState({
                                isAlertModalOpened: true,
                                alertModalTitle: "Erro ao cancelar nota fiscal",
                              });
                            }
                          }}
                        >
                          Cancelar Nota Fiscal
                        </div>
                        {!this.props.isNotCancelable && (
                          <div
                            className="btn"
                            style={{
                              position: "absolute",
                              color: "white",
                              bottom: "0",
                              left: "0",
                              backgroundColor: "rgba(232,34,45,1)",
                              border: "2px solid rgba(232,34,45,1)",
                            }}
                            onClick={this.openModalCancel.bind(this)}
                          >
                            Cancelar Pedido
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          background: "#d18b00",
                          color: "white",
                          padding: "10px",
                        }}
                        className="btn btn-transparent"
                        onClick={() => {
                          printProduct(
                            this.props.order.id,
                            this.props.activeModal
                          );
                        }}
                      >
                        Imprimir Nota Fiscal
                      </div>
                      <NavLink
                        style={{ background: "#d18b00", color: "white" }}
                        className="btn btn-transparent"
                        exact
                        to={`/print/${this.props.order.id}`}
                        target="_blank"
                      >
                        Imprimir Pedido
                      </NavLink>
                      <NavLink
                        style={{ background: "#d18b00", color: "white" }}
                        className="btn btn-transparent"
                        exact
                        to={{
                          pathname: "/pedido",
                          state: { editingOrder: this.props.order.id },
                        }}
                      >
                        Editar Pedido
                      </NavLink>
                    </OrderInfo>
                  </ColHalf>
                  <ColHalf>
                    <div className="info__delivery">
                      <h4>Endereço de Entrega</h4>
                      {this.props.order.address ? (
                        <React.Fragment>
                          <p className="address">
                            {this.props.order.address.streetName},{" "}
                            {this.props.order.address.streetNumber}
                          </p>
                          <p className="address">
                            {this.props.order.address.complement}
                          </p>
                          <p className="address">
                            {this.props.order.address.cep}
                          </p>
                          {/* {this.props.order.createdFor &&
                          this.props.order.createdFor.phoneNumber ? (
                            <p className="tel">
                              {this.props.order.createdFor.phoneNumber}
                            </p>
                          ) : (
                            ``
                          )} */}
                        </React.Fragment>
                      ) : (
                        <p>Nenhum endereço cadastrado</p>
                      )}
                      <ButtonAction
                        onClick={() =>
                          this.props.openModalAddress(this.props.order.address)
                        }
                      >
                        Alterar endereço de entrega
                      </ButtonAction>
                    </div>
                    <div className="info__payment">
                      <h4>Meio de Pagamento</h4>
                      <p>
                        {this.props.order.paymentMode !== "cash"
                          ? `Cartão de crédito (${this.props.order.flag})`
                          : "Dinheiro"}
                      </p>
                      <ButtonAction
                        onClick={() => this.props.openModalPayment()}
                      >
                        Alterar meio de pagamento
                      </ButtonAction>
                    </div>
                    <div className="info__observations">
                      <h4>Obervações</h4>
                      <div>
                        <p>
                          {this.props.order.observations
                            ? this.props.order.observations
                            : "Não há observações."}
                        </p>
                      </div>
                    </div>
                  </ColHalf>
                </Row>
              </ColHalf>
              <ColHalf>
                <Orders>
                  {this.props.order.items.length > 0
                    ? this.props.order.items.map((item, index) => {
                        let product = getProductByID(
                          JSON.parse(window.localStorage.getItem("products")),
                          item.product.id
                        );
                        return (
                          <article key={item.id}>
                            <div className="leftSide">
                              <h3>{item.product.name}</h3>
                              {product.ingredients.length > 0 ? (
                                <p>
                                  {product.ingredients
                                    .map((ing) => {
                                      return ing.name;
                                    })
                                    .join(", ")}
                                </p>
                              ) : (
                                ``
                              )}
                              {item.extra.add_extra_ingredients.length > 0 ||
                              item.extra.exclude_ingredients.length > 0 ? (
                                <div className="ingredients">
                                  {item.extra.exclude_ingredients.map((obj) => (
                                    <Ingredients key={obj.name}>
                                      - {obj.name}
                                    </Ingredients>
                                  ))}
                                  {item.extra.add_extra_ingredients.map((obj) =>
                                    obj.ingredient ? (
                                      <Ingredients
                                        key={obj.ingredient.name}
                                        extra
                                      >
                                        + {obj.quantity}x {obj.ingredient.name}
                                      </Ingredients>
                                    ) : (
                                      ``
                                    )
                                  )}
                                </div>
                              ) : (
                                ``
                              )}
                              <p>
                                <strong>Condimentos:</strong>{" "}
                                {item.spices.map((c, y) => {
                                  return `${c.quantity}x ${c.spice.name}${
                                    y !== item.spices.length - 1 ? `, ` : ""
                                  }`;
                                })}
                              </p>
                              <p>
                                <strong>Observações:</strong>{" "}
                                {item.observations}
                              </p>
                              <div className="actions">
                                <LinkAction
                                  to={`/pedido/${item.order_id}/${item.id}`}
                                >
                                  {" "}
                                  Alterar item
                                </LinkAction>
                                <ButtonAction
                                  onClick={() => this.openModalObs(item)}
                                >
                                  {item.observations === "Não há observações."
                                    ? `Adicionar`
                                    : `Editar`}{" "}
                                  Observação
                                </ButtonAction>
                              </div>
                            </div>
                            <div className="rightSide">
                              <h3>
                                R${" "}
                                {parseFloat(item.price)
                                  .toFixed(2)
                                  .replace(".", ",")}
                              </h3>
                            </div>
                          </article>
                        );
                      })
                    : ""}
                </Orders>
              </ColHalf>
            </Row>
          </ModalDetailsBlock>
        </ModalContainer>
        {this.state.isAlertModalOpened && (
          <div>
            <AlertModal
              title={this.state.alertModalTitle}
              onClose={() => {
                this.setState({ isAlertModalOpened: false });
              }}
            />
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalOrderDetails;
