import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useStock } from '../hooks/useStock';

import Header from '../components/Header';
import Menu from '../components/Menu';

import ArrowIcon from "../assets/svg/arrow.svg";

const Stock = () => {
  const [isTableOpened, setIsTableOpened] = useState(true);
  const [quantityValues, setQuantityValues] = useState({});
  // const [breads, setBreads] = useState([]);

  const { breads, updateBreads } = useStock();

  useEffect(() => {
    let objectQuantityValues = {};
    breads.forEach(item => {
      objectQuantityValues[item.name] = item.quantity;
    });

    setQuantityValues(objectQuantityValues);
  }, [breads]);

  const toggleTable = () => {
    setIsTableOpened(!isTableOpened);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuantityValues({ ...quantityValues, [name]: value });
  }

  const handleSubmit = (quantityValues) => {
    updateBreads(quantityValues);
  }

  return (
    <>
      <Header />
      <Menu />
      <Container>
        <TableTitle>
          <img
            style={isTableOpened ? {} : { transform: "rotate(-90deg)" }}
            onClick={toggleTable}
            src={ArrowIcon}
            alt="Seta"
          />
          <h2>Pães</h2>
        </TableTitle>
        {isTableOpened && (
          <>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th style={{ minWidth: '500px' }}>Quant.</th>
                  </tr>
                </thead>

                <tbody>
                  {breads.map((item) => (
                    <tr key={item.id}>
                      <td className="font-style">{item.name}</td>
                      <td className="font-style">
                        <input value={quantityValues[item.name]} name={item.name} onChange={handleInputChange} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
            <Footer>
              <button onClick={() => handleSubmit(quantityValues)}>Salvar alterações</button>
            </Footer>
          </>
        )}
      </Container>
    </>
  );
}

const Container = styled.main`
  height: 100vh;
  width: 100%;
  background: #FAFAFA;
  padding: 13px 36px;
`;

const TableTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    cursor: pointer;
  }

  h2 {
    font-family: "ArialMT";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    color: #21201F;

    margin-left: 7px;
  }
`;

const TableContainer = styled.div`
  overflow:auto;
  max-height: 350px;

  table {
    width: 100%;
    position: relative;
    border-collapse: collapse; 

    thead {
      tr {
        height: 50px;
        border-radius: 15px;
      }
      
      th {
        background: #D18B00;
        position: sticky;
        top: 0; 
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);

        color: #FFF;
        padding: 16px 30px;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        text-align: left;
      }
    }

    tbody {
      tr {
        -webkit-box-shadow: 0px 0px 3px -2px #000000; 
        box-shadow: 0px 0px 3px -2px #000000;}

      tr:nth-child(even) {
        background-color: #F7F7F7;
      }
      
      td {
        color: #21201F;
        padding: 16px 30px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        border: 0;
        font-weight: 700;

        input {
          height: 30px;
          width: 70px;
          background: #FFFFFF;
          border: 1px solid #21201F;

          font-size: 18px;
          font-weight: 400;
          text-align: right;
          color: #21201F;
        }
      }
    }
  }
`;

const Footer = styled.div`
  margin-top: 22px;

  button {
    border: none;
    border-radius: 4px;
    background: #d18b00;
    color: white;
    padding: 9px 29px;
    font-family: Proxima;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    cursor: pointer;
  }
`;

export default Stock;