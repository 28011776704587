import React from "react";
import styled from "styled-components";
import { ReactComponent as Next } from "../assets/svg/next-light.svg";
import { Draggable } from "react-beautiful-dnd";
import variables from "../assets/styles/variables";

const Product = styled.a`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.isDragging ? variables.card.backgroundActive : "#fff"};
  border: 1px solid
    ${(props) =>
      props.isDragging ? variables.card.borderActive : "transparent"};

  &:after {
    content: "";
    display: block;
    height: 2px;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
  }

  .name {
    color: #414141;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .price {
    color: #838383;
    font-family: "ArialMT";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    display: inline-flex;
  }

  .product__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
  }

  svg {
    height: 18px;
    margin-left: 6px;
  }

  .ingredients {
    color: #414141;
    font-family: "ArialMT";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  &.disabled {
    cursor: default;
  }
`;

const ProductListCard = (props) => (
  <Draggable draggableId={`${props.index}`} index={props.index}>
    {(provided, snapshot) => (
      <li
        key={props.id}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <Product
          to="#"
          className={`
                                    ${
                                      props.active !== props.id
                                        ? `inactive`
                                        : ``
                                    }
                                    ${props.edit ? `disabled` : ``}
                                    `}
          product={props.id}
          onClick={() => {
            if (!props.edit) props.onProductClick(props);
          }}
          isDragging={snapshot.isDragging}
        >
          <div className="product__info">
            <span className="name">{props.name}</span>
            <span className="price">
              R$ {props.price.replace(".", ",")} <Next fill="#F29448"></Next>
            </span>
          </div>
          {props.ingredients.length > 0 ? (
            <p className="ingredients">
              {props.ingredients.map((ing) => ing.name).join(", ")}
            </p>
          ) : (
            ``
          )}
        </Product>
      </li>
    )}
  </Draggable>
);

export default ProductListCard;
