import React from 'react';
import styled from 'styled-components';
import {Modal, ModalClose} from './Modal';
import {ReactComponent as Close } from '../assets/svg/close.svg';
import Axios from '../Axios';


const ModalDetailsBlock = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    min-width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    text-align: left;

    h2{
        color: #262524;
        font-family: Arial;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .btn{
        border-radius: 4px;
        width: 100%;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        padding: 17px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        background: #EEBA1A;
        color: #000;
        cursor: pointer;
        border: none;
    }

    textarea{
        width: 361px;
        height: 149px;
        border: 1px solid #838383;
        resize: none;
        color: #838383;
        padding: 15px;
        font-family: 'ArialMT';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 24px;
    }
`

const ModalContainer = styled.div`
    background-color: #fff;
    position: relative;
    display: flex;
    padding: 32px;
    max-width: 425px;
    width: 100%;
    border-radius: 4px;

    p{
        color: #858585;
        font-family: 'ArialMT';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 14px;
    }
`


class ModalObs extends React.Component{ 
    state = {
        obs: '',
        message: null,
        shouldRenderMessage: false
    }
    componentDidMount(){
        this.setState({obs: this.props.obj.observations});
    }

    updateObs(obj){
        Axios.put(`/order-products/${this.props.obj.id}`, obj).then(() => {
            this.setState({obs: obj.observations, shouldRenderMessage: false});
            this.props.close(obj.observations);
        })
        .catch(res => {
            this.setState({shouldRenderMessage: true, message: res.response.data})
        });
    }

    saveObs(){
        let obs = document.querySelector("#observation").value;

        if(this.props.edit){
            let data = {
                "observations": obs
            }
            this.updateObs(data);
            return;
        }


        let removed = this.props.obj.extra.exclude_ingredients ? this.props.obj.extra.exclude_ingredients.map(removed => removed.id) : [],
            extra = this.props.obj.extra.add_extra_ingredients ? this.props.obj.extra.add_extra_ingredients.map(extra => {return { ingredient: extra.ingredient.id, quantity: extra.quantity}}) : [],
            data = {
                "product_id": this.props.obj.id,
                "observations": obs,
                "exclude_ingredients": removed,
                "add_extra_ingredients": extra,
                "quantity": 1,
            }
            
        Axios.put(`/inventory/items/update/${this.props.obj.inventory_ref}`, data).then(() => {
            this.setState({obs: obs, shouldRenderMessage: false});
            this.props.close();
        })
        .catch(res => {
            this.setState({shouldRenderMessage: true, message: res.response.data})
        });
    }

    onEnter(event){
        const { keyCode, target } = event;
        const { selectionStart, value } = target;
        const bullet = "\u2022";
        const bulletWithSpace = `${bullet} `;
        const enter = 13;
        
        if (keyCode === enter) {
            target.value = [...value]
            .map((c, i) => i === selectionStart - 1
                ? `\n${bulletWithSpace} `
                : c
            )
            .join('');
            
            target.selectionStart = selectionStart+bulletWithSpace.length;
            target.selectionEnd = selectionStart+bulletWithSpace.length;
        }
        
        if (value[0] !== bullet) {
            target.value = `${bulletWithSpace}${value}`;
        }
	}

	handleClick = (e) => {
		if (e.target !== e.currentTarget) return;
		this.props.close();
	};	
    
    render(){
        return(
            <Modal className="modal-order-details" onClick={this.handleClick}>
                <ModalContainer>
                    <ModalClose href="#" onClick={() => this.props.close()}><Close></Close></ModalClose>
                    <ModalDetailsBlock>
                        <h2>Observação</h2>
                        <textarea onKeyUp={e => this.onEnter(e) }id="observation" placeholder="Digite aqui a observação" defaultValue={ this.props.obj.observations === 'Não há observações.'? null : this.props.obj.observations }></textarea>
                        {this.state.shouldRenderMessage ? <p>{this.state.message}</p> : ``}
                        <button className="btn" onClick={this.saveObs.bind(this)}>{this.props.edit ? 'EDITAR':'ADICIONAR'}</button>
                    </ModalDetailsBlock>
                </ModalContainer>
            </Modal>
        )   
    }
}

export default ModalObs;