import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import userService from "../helpers/userService";

import variables from "../assets/styles/variables";
import Logo from "../assets/svg/sanata-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const HeaderContainer = styled.header`
  height: 70px;
  background-color: ${variables.header.background};
  display: flex;
  justify-content: center;
  position: relative;

  img {
    height: 100%;
  }
`;

const ButtonNewOrder = styled(NavLink)`
  border-radius: 4px;
  background: #d18b00;
  color: #fff;
  padding: 8px 24px;
  font-family: Proxima;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  cursor: pointer;

  position: absolute;
  left: 30px;
  top: 18px;

  text-decoration: none;
`;

// const ButtonNewOrderExpress = styled(NavLink)`
//   border-radius: 4px;
//   background: #d18b00;
//   color: #fff;
//   padding: 8px 24px;
//   font-family: Proxima;
//   font-size: 14px;
//   font-weight: 700;
//   line-height: 17px;
//   cursor: pointer;

//   position: absolute;
//   left: 180px;
//   top: 18px;

//   text-decoration: none;
// `;

const Logout = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  color: #ffffff;
  font-family: Proxima, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  outline: none;
  cursor: pointer;

  position: absolute;
  right: 30px;
  height: 100%;

  &:hover {
    opacity: 0.9;
    transition: 0.3s all;
  }

  svg {
    margin-right: 8px;
  }
`;

class Header extends React.Component {
  onClickLogout() {
    userService.logout();
    window.location.reload();
  }

  render() {
    return (
      <HeaderContainer>
        <ButtonNewOrder exact to="/pedido">
          Novo pedido
        </ButtonNewOrder>
        {/* <ButtonNewOrderExpress exact to="/pedido-expresso">
          Pedido Expresso
        </ButtonNewOrderExpress> */}
        <NavLink to="/painel">
          <img src={Logo} alt="logo" style={{ cursor: "pointer" }} />
        </NavLink>
        {localStorage.getItem("token") ? (
          <Logout onClick={this.onClickLogout.bind(this)}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            Encerrar
          </Logout>
        ) : (
          ``
        )}
      </HeaderContainer>
    );
  }
}

export default Header;
