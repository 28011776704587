import React from 'react';

import styled,{css} from "styled-components";

const Select = ({
  options = [],
  value,
  name,
  style,
  children,
  onChange = () => {},
  refs,
  disabled,
}) => {
  return (
    <SelectContainer disabled={disabled}>
      <select disabled={disabled} onChange={onChange} value={value}>
        {options.map((option, index) => (
          <option value={option} key={index}>{option}</option>
        ))}
      </select>
    </SelectContainer>
  );
} 

const SelectContainer = styled.div`
  ${props => props.disabled && css`
    opacity: 0.4;
    cursor: not-allowed;
    select {
      opacity: 0.8;
      cursor: not-allowed;
    }
  `}
  width: 180px;
  height: 42px;
  background: #ffffff;
  cursor: pointer;
  
  
  border: 1.5px solid #21201F;
  border-radius: 3px;
  
  padding: 12px 14px;
  outline: 0;
  
  margin-left: 14px;
  
  select {
    width: 100%;
    border: none;
    background: #ffffff;
    cursor: pointer;
    
    color: rgba(0, 0, 0, 0.75);
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 400;
  }
`;

export default Select;
