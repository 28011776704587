import React from 'react';
import { Reset } from 'styled-reset'
import { BrowserRouter } from "react-router-dom";

import { StockProvider } from './hooks/useStock';

import GlobalStyles from './assets/styles/global';
import Routes from './routes.js';


function App() {
  return (
    <StockProvider>
      <BrowserRouter>
        <div className="App">
          <GlobalStyles />
          <Reset />
          <Routes />
        </div>
      </BrowserRouter>
    </StockProvider>
  );
}

export default App;
