let variables = {
    body: {
        background: '#EFEFEF',
        fontFamily: 'Proxima, sans-serif'
    },
    header : {
        background: '#21201f'
    },
    card : {
        background: '#ffffff',
        backgroundHighlightedOrder: '#d18b0055',

        border: '#E3E3E3',
        borderActive: '#D18B00',
        backgroundActive: '#f7f7f7'
    }
}

export default variables;