import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ProductListCard from "./ProductListCard";

export default class ProductList extends React.Component {
  render() {
    return (
      <Droppable droppableId="product-list">
        {(provided, snapshot) => (
          <ul
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            className="list-orders"
            ref={provided.innerRef}
          >
            {this.props.products.map((p, index) => (
              <ProductListCard
                active={this.props.active}
                edit={this.props.edit}
                onProductClick={this.props.onProductClick}
                index={index}
                {...p}
              />
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  }
}
