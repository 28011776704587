import styled from 'styled-components';

const Modal = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(38, 37, 36, 0.75);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    background-color: #fff;
    display: flex;
    position: relative;
    display: inline-block;
    padding: 32px;
`

const ModalTitle = styled.h1`
    color: #414141;
    font-family: Arial;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
`

const ModalClose = styled.button`
    position: absolute;
    right: 24px;
    top: 24px;
    background-color: transparent;
    cursor:pointer;
    border: none;
    outline: 0;

    svg{
        width: 14px;
        height: 14px;
    }
`

export { Modal, ModalContainer, ModalTitle, ModalClose };