import styled from "styled-components";
import React from "react";

import Tick from "../assets/svg/tick.svg";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  color: #424242;
  font-family: ArialMT;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  &:before,
  &:after {
    content: "";
    display: inline-block;
  }

  &:before {
    border: 1px solid #838383;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
  }

  &:after {
    position: absolute;
    left: 2px;
  }

  &.disabled {
    pointer-events: none;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + label:after {
    border-radius: 100%;
    width: 22px;
    height: 22px;

    content: "";
    background: url(${Tick}) no-repeat;
    background-size: 11px;
    background-position: center;
    background-color: #7dc74b;
  }
`;

class Checkbox extends React.Component {
  render() {
    return (
      <CheckboxContainer className="checkbox__container">
        <Input
          defaultChecked={this.props.defaultChecked}
          name={this.props.inputName}
          id={this.props.id}
          type={this.props.type ? this.props.type : `checkbox`}
          data-value={this.props.dataValue}
          onChange={this.props.onChange}
        ></Input>
        <Label
          onClick={this.props.onClick}
          htmlFor={this.props.id}
          className={this.props.disabled && "disabled"}
        >
          {this.props.name} <span>{this.props.sub}</span>
        </Label>
      </CheckboxContainer>
    );
  }
}

export default Checkbox;
