import React from "react";
import styled from "styled-components";
import CurrencyInput from "react-currency-input";
import ReactInputMask from "react-input-mask";

const Label = styled.label`
  color: #414141;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  display: inline-block;
`;

const InputTextMask = styled(ReactInputMask)`
  width: 100%;
  border: 1px solid #979797;
  height: 42px;
  line-height: 42px;
  color: #858585;
  font-family: "ArialMT";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 0 12px;
  outline: 0;
`;

const InputText = styled.input`
  width: 100%;
  border: 1px solid #979797;
  height: 42px;
  line-height: 42px;
  color: #858585;
  font-family: "ArialMT";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 0 12px;
  outline: 0;
`;

const InputMoney = styled(CurrencyInput)`
  width: 100%;
  border: 1px solid #979797;
  height: 42px;
  line-height: 42px;
  color: #858585;
  font-family: "ArialMT";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding-left: 12px;
  outline: 0;
`;

const InputContainer = styled.div`
  width: 100%;
  margin: ${(props) => (props.noMargin ? `0px` : `8px 0`)};

  &.search {
    padding: 20px 24px;
    margin: 0;
    position: relative;
    &::after {
      content: "";
      display: block;
      height: 2px;
      background-color: #cccccc;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &.half {
    display: flex;
    flex-direction: row;

    .half-container {
      width: 50%;

      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
`;

const Input = ({ icon, placeholder, inputStyle = {} }) => (
  <div style={{ display: "flex" }}>
    {icon && (
      <div style={{ display: "flex" }}>
        <img
          src={icon}
          alt="Ícone"
          style={{
            border: "1px solid #979797",
            borderRight: 0,
            backgroundColor: "#fff",
            paddingLeft: "10px",
          }}
        />
      </div>
    )}
    <InputText
      placeholder={placeholder}
      style={Object.assign({}, inputStyle, { borderLeft: 0 })}
    />
  </div>
);

export { Label, InputText, InputTextMask, InputContainer, InputMoney, Input };
