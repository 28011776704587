import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Next } from '../assets/svg/next-light.svg';


export const CategoryTitle = styled.h3`
    color: #424242;
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
`

const ArrowTitleContainer = styled.button`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
    cursor: ${props => props.isClickable ? `pointer` : `default`};
    border: 0;
    background-color: transparent;
    outline: none;
    padding: 0;

    h3{
        color: #414141;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }
    svg{
        height: 18px;
        margin-left: 6px;
    }

    &:active{
        h3, svg{
            opacity: 0.7;
        }
    }
`

export const ArrowTitle = (props) => {
    return (
        <ArrowTitleContainer type="button" isClickable={props.isClickable} onClick={props.onClick}>
            <h3>{props.title}</h3>
            <Next fill="#F29448"></Next>
        </ArrowTitleContainer>
    )
}

export const Description = styled.p`
    color: #424242;
    font-family: ArialMT;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    ${props => props.lineClamp ?
        `
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: ${props.lineClamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        `: ``
    }
`

export const SectionTitle = styled.h2`
    color: #424242;
    font-family: 'ArialMT';
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;
`