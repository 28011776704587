import styled from 'styled-components'

const ColumnWrapper = styled.div`
    display: flex;
`

const Column = styled.div`
    margin: ${props => props.noMargin ? `0px` : `15px`};
    width: 25%;
    height: 100vh;
`


export { ColumnWrapper, Column } 