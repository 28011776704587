import React from 'react'
import styled from 'styled-components'

const NumericContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
`

const NumericButton = styled.button`
    background-color: #D18B00;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 300;
    font-family: 'ArialMT';
    padding: 0;
    width: 23px;
    height: 23px;
    outline: none;
    position: absolute;

    &:hover{
        opacity: 0.7;
    }

    &:active{
        opacity: 0.5;
    }

    ${props => props.increase ? `
        left: 51px;
        &:after, &:before{
            background-color: #FFFFFF;
            border-radius: 0.5px;
            width: 13px;
            height: 1px;
            content: "";
            position: absolute;
        }

        &:after{
            transform: rotate(90deg);
        }
    ` : ``}
    ${props => props.decrease ? `
        left: -15px;
        &:after{
            background-color: #FFFFFF;
            border-radius: 0.5px;
            width: 13px;
            height: 1px;
            content: "";
        }
    ` : ``}
`

const NumericViewer = styled.span`
	margin-right: ${props => props.marginRight || "0px"};
    background-color: #E6E6E6;
    width: 66px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #515151;
    font-family: 'ArialMT';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
`

export default class Numeric extends React.Component{
    state = {
		value: 1,
		min: 1
    }

    componentDidMount(){		
		if (this.props.min !== undefined && this.props.min !== null) 
			this.setState({min: this.props.min});
        this.setState({
            value: this.props.defaultValue ? this.props.defaultValue : 1
        });
    }

    increment(){
        this.setState({ value : this.state.value + 1 }, () => this.props.onChange(this.state.value));
    }

    decrement(){
        this.setState({ value: this.state.value > this.state.min ? this.state.value - 1 : this.state.min }, () => this.props.onChange(this.state.value));
    }

    render(){
        return(
            <NumericContainer>
                <NumericButton type="button" onClick={this.increment.bind(this)} increase={true}></NumericButton>
                <NumericViewer marginRight={this.props.marginRight} >{this.state.value}</NumericViewer>
                <NumericButton type="button" onClick={this.decrement.bind(this)} decrease={true}></NumericButton>
                <input type="hidden" name={this.props.name} defaultValue={this.state.value}/>
            </NumericContainer>
        )
    }
}