import React from "react";
import styled from "styled-components";
import Condiments from "./Condiments";

const CardOrderBlock = styled.article`
  margin-bottom: 25px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  &.order {
    h3 {
      font-family: "ArialMT";
      font-weight: bold;
      margin-bottom: 4px;
      display: flex;

      span {
        color: #838383;
        font-family: "ArialMT";
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-left: auto;
      }
    }

    p {
      color: #414141;
      font-family: "ArialMT";
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      margin-bottom: 4px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 14px;

    button {
      color: #d18b00;
      font-family: Arial;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      text-decoration: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 7px 0;
      outline: 0;
      text-align: left;

      &:hover {
        opacity: 0.7;
        transition: 0.3s all;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button {
        margin: 0px 0px 8px 0;
      }
    }
  }

  .order__info {
    width: 100%;
  }

  .order__qt {
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
`;

const Ingredients = styled.span`
  background-color: ${(props) => (props.extra ? "#0CB96C" : "#D1004D")};
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  padding: 3px;
  margin: 2px;
  display: inline-block;
`;

class OrderCard extends React.Component {
  state = {
    product: null,
  };

  openModalObs() {
    this.props.onClickOpenObs(this.props);
  }

  onClickEdit() {
    this.props.onClickEditOrder(this.props);
  }

  onClickRemove() {
    this.props.onClickRemoveOrder(this.props);
  }

  render() {
    return (
      <CardOrderBlock className="order" key={this.props.id}>
        <section className="order__info">
          <h3>
            {this.props.name} <span>{this.props.price}</span>
          </h3>
          <p>
            {this.props.ingredients
              ? this.props.ingredients.map((ing) => ing.name).join(", ")
              : ``}
          </p>
          <div className="ingredients">
            {this.props.extra.exclude_ingredients.map((obj) => (
              <Ingredients key={obj.name}>- {obj.name}</Ingredients>
            ))}
            {this.props.extra.add_extra_ingredients.map((obj) => (
              <Ingredients key={obj.ingredient.name} extra>
                + {obj.quantity}x {obj.ingredient.name}
              </Ingredients>
            ))}
          </div>
          <div className="actions">
            <div className="actions-block">
              <button onClick={this.onClickRemove.bind(this)}>
                Remover item
              </button>
              <button onClick={this.onClickEdit.bind(this)}>
                Alterar item
              </button>
            </div>
            <div className="actions-block">
              <button onClick={() => this.props.onDuplicateOrder(this.props)}>
                Duplicar item
              </button>
              <button onClick={this.openModalObs.bind(this)}>
                {this.props.observations === "Não há observações."
                  ? `Adicionar`
                  : `Editar`}{" "}
                Observação
              </button>
            </div>
          </div>
          {this.props.category === 1 && (
            <Condiments
              onCondimentsUpdate={this.props.onCondimentsUpdate}
              product={this.props}
            ></Condiments>
          )}
        </section>
        {/* <section className="order__qt">
                    <Numeric
                        defaultValue={1}
                        onChange={this.onNumericChange.bind(this)}
                        name="order__qt"/>
                </section> */}
      </CardOrderBlock>
    );
  }
}

export default OrderCard;
