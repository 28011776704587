import React from "react";
import styled from "styled-components";
import variables from "../assets/styles/variables";

import { ReactComponent as IconNext } from "../assets/svg/next.svg";
import { Draggable } from "react-beautiful-dnd";

const Order = styled.article`
  background-color: ${(props) =>
    props.transactionId
      ? variables.card.backgroundHighlightedOrder
      : variables.card.background};
  border: 1px solid
    ${(props) =>
      props.isDragging ? variables.card.borderActive : variables.card.border};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${variables.card.border};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
`;
const OrderInfo = styled.aside`
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 7px;
  }

  h3 {
    font-weight: 700;
    font-family: "ArialMT";
    font-size: 16px;
  }

  p {
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 5px;
  }

  button {
    color: #838383;
    font-family: "ArialMT";
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    outline: 0;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: #000;
      transition: 0.3s all;
    }
  }
`;

const TagMobile = styled.div`
  padding: 2px 6px;
  border: 1px solid ${variables.card.borderActive};
  background: ${variables.card.borderActive};
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
`;

const OrderAction = styled.aside`
  display: flex;
  align-items: center;

  color: #afafaf;
  font-family: "ArialMT";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;

const NextAction = styled.a`
  color: ${variables.card.borderActive};
  font-family: "ArialMT";
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  svg {
    display: flex;
    border: 1px solid ${variables.card.borderActive};
    border-radius: 100%;
    width: 28px;
    height: 28px;
    padding: 6px;
    fill: ${variables.card.borderActive};
    margin-bottom: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

class Card extends React.Component {
  state = {
    status: null,
    active: null,
  };

  componentDidMount() {
    this.setState({ status: this.props.status, active: this.props.active });
  }

  render() {
    return (
      <Draggable
        draggableId={`${this.props.id}`}
        index={this.props.index}
        isDragDisabled={this.props.isDragDisabled}
      >
        {(provided, snapshot) => (
          <div>
            <Order
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
              className={`order ${this.state.active ? "active" : ""}`}
              data-order-id={this.props.id}
              data-order-status={this.props.status}
              transactionId={this.props.transactionId}
              key={this.props.id}
            >
              <OrderInfo className="order__info">
                <div>
                  <h3>#{this.props.id}</h3>
                  {this.props.transactionId && <TagMobile>Via App</TagMobile>}
                </div>
                <p style={{textTransform: 'capitalize'}}>{this.props.name.toLowerCase()}</p>
                <button
                  onClick={() => this.props.onClickOrderDetails(this.props.id)}
                >
                  Ver Detalhes
                </button>
              </OrderInfo>

              <OrderAction className="order__action">
                {this.props.status !== "delivered" && (
                  <NextAction
                    href="#"
                    onClick={() =>
                      this.props.onClickMoveOrder(
                        this.props.id,
                        this.props.status,
                        this
                      )
                    }
                  >
                    <IconNext />
                    Avançar pedido
                  </NextAction>
                )}
              </OrderAction>
            </Order>
          </div>
        )}
      </Draggable>
    );
  }
}

export default Card;
