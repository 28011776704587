import React from "react";
import { Modal, ModalContainer, ModalTitle, ModalClose } from "./Modal";
import { ReactComponent as Close } from "../assets/svg/close.svg";

const AlertModal = ({ title = "", onClose = () => {} }) => {
  const handleClick = (e) => {
    if (e.target !== e.currentTarget) return;
    onClose();
  };

  return (
    <Modal onClick={handleClick}>
      <ModalContainer style={{ padding: "45px" }}>
        <ModalClose onClick={onClose} style={{ top: "15px", right: "10px" }}>
          <Close />
        </ModalClose>
        <ModalTitle>
          <h1>{title}</h1>
        </ModalTitle>
      </ModalContainer>
    </Modal>
  );
};

export default AlertModal;
