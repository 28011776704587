import React from "react";
import styled from "styled-components";
import {Modal, ModalTitle} from "./Modal";
import {Button} from "./Button";

const ModalDetailsBlock = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  min-width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  text-align: left;

  h1 {
    color: #414141;
    font-family: Arial;
    font-size: 26px;
    font-weight: 700;
    line-height: 29px;
  }

  p {
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    margin: 20px 0 11px;
    color: #424242;

    strong {
      font-weight: 700;
    }

    &.last {
      margin-bottom: 24px;
    }
  }

  ul {
    margin-bottom: 20px;
  }

  ul li {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;

    &:first-child {
      margin-top: 20px;
    }
  }

  ul li input[type="radio"] {
    display: none;
  }

  ul li input[type="radio"] + label {
    position: relative;
    cursor: pointer;
    display: inline-flex;

    &:before,
    &:after {
      display: inline-block;
      content: "";
    }
    &:before {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border-radius: 0;
      border: 1px solid #000;
    }
  }

  ul li input[type="radio"]:checked + label {
    &:after {
      width: 14px;
      height: 14px;
      background: #7dc74b;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }

  ul li {
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }

  .btn {
    border-radius: 4px;
    width: 100%;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    padding: 17px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    background: #eeba1a;
    color: #000;
    margin-bottom: 15px;
    cursor: pointer;
    border: none;

    &-transparent {
      border: 2px solid #d18b00;
      color: #d18b00;
      background: transparent;
    }
  }
`;

const ModalContainer = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  padding: 32px;
  max-width: 449px;
  width: 100%;
  border-radius: 4px;
  justify-content: center;
`;

class ModalCreditCards extends React.Component {
  handleClick = (e) => {
    if (e.target !== e.currentTarget) return;
	this.props.close();
  };	

  render() {
    const creditCards = [
        "Visa",
        "Master",
        "Banri",
        "Banri Alimentação",
        "Alelo refeição",
        "Elo",
        "Ticket restaurante",
        "Flexo Card"
    ]

    return (
      <Modal className="modal-order-details" onClick={this.handleClick}>
        <ModalContainer>
          <ModalDetailsBlock>
            <ModalTitle>Selecione a bandeira do cartão</ModalTitle>
            <p>Maquina de cartão:</p>

            <ul>
                {
                    creditCards.map((c, i) => (
                        <li>
                            <input checked={c === this.props.active} onChange={() => this.props.onChange(c)} id={`radio-${i}`} name="credit-card" type="radio" value={c}></input>
                            <label htmlFor={`radio-${i}`}>{c}</label>
                        </li>
                    ))
                }
            </ul>

            <Button
              type="button"
              onClick={() => this.props.close()}
              margin={`12px 0 0`}
            >
              CONFIRMAR
            </Button>
          </ModalDetailsBlock>
        </ModalContainer>
      </Modal>
    );
  }
}

export default ModalCreditCards;
