import React from 'react'
import styled from 'styled-components';

import { ReactComponent as Next } from '../assets/svg/next-light.svg';


export const Button = styled.button`
    border: none;
    background-color: ${props => props.disabled ? "#DADADA" : "#EEBA1A"};
    color: ${props => props.disabled ? '#9E9E9E' : '#000'};
    width: ${props => props.width ? props.width: `100%`};
    padding: 18px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    font-family: 'ArialMT';
    text-align: center;
    border-radius: 4px;
    margin-top: ${props => props.noMargin? 0 : `24px`};
    cursor: ${props => props.disabled === true ? 'default' : 'pointer'};
    outline: 0;
    margin: ${props => props.margin ? `${props.margin}` : `0px`};
    
    &:hover{
        opacity: 0.7;
        transition: opacity .3s;
    }

    ${props => props.transparent ? 
        `border: 2px solid #D18B00;
        color: #D18B00;
        background: transparent;` : ``}
`

const ArrowConfirmWrapper = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    outline: none;
    padding: 0;
    cursor: pointer;

    svg{
        height: 18px;
        transform: rotate(180deg);
        fill: rgb(242, 148, 72);
    }

    p{
        color: #D18B00;
        font-family: 'ArialMT';
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        margin-left: 8px;
    }

    &:active{
        svg, p{
            opacity: .7;
        }
    }
`

export const ArrowConfirm = (props) => {
    return (
        <ArrowConfirmWrapper
            style={props.style}
            onClick={props.onClick}>
                <Next fill="#F29448"></Next>
                <p>
                    {props.title}
                </p> 
        </ArrowConfirmWrapper>

    )
}