import React from 'react';
import styled from 'styled-components'
import {Label, InputText, InputContainer} from './InputText';

import {Button} from './Button'
import Icon from '../assets/svg/icone.png';

import Axios from '../Axios';


const Wrapper = styled.section`
    padding: 20px 15px;
`

const H2 = styled.h2`
    color: #424242;
    font-family: 'ArialMT';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 32px;
`

const Table = styled.table`
    margin-top: 16px;
    width: 100%;

    thead{
        background-color: transparent;

        td{
            color: #262524;
            font-family: 'ArialMT';
            font-size: 12px;
            font-weight: 700;
            padding: 12px 0;
        }
    }

    tbody{
        background-color: #fff;
        border: 1px solid #E3E3E3;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #E3E3E3;
        padding-bottom: 14px;

        tr{
            border-bottom: 1px solid #CCCCCC;
            cursor: pointer;

            td{
                color: #262524;
                font-family: 'ArialMT';
                font-size: 12px;
                font-weight: 400;
                padding: 8px 0;
                position: relative;

                span{
                    padding: 8px;
                    display: inline-flex;
                    width: 85%;
                }

                &:first-child{
                    padding-left: 8px;
                }

                &:last-child{
                    padding-right: 8px;
                }
            }

            // &:hover td span{
            //     background-color: #F7E234;
            // }
        }

    }
`

const ContainerTable = styled.div`
    max-height: calc(100vh - 348px);
    overflow: hidden auto;
`

const Editar = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    background: transparent;
    border: none;
    color: #D18B00;
    cursor: pointer;
    min-height: 40px;
    padding: 0 8px;

    display: inline-flex;
    align-items: center;

`;

export default class SearchUser extends React.Component{

    state = {
        users : []
    }

    componentDidMount(){
        this.search();
    }

    async search(){
        await Axios.get(`/profiles?search=${this.searchInput.value}`)
            .then(res => this.setState({users: res.data}));
    }

    render(){
        return(
            <Wrapper>
                <H2>Selecione um cliente</H2>
                <div style={{width: '425px'}}>
                    <InputContainer>
                        <Label>Procure por nome ou telefone</Label>
                        <InputText ref={input => this.searchInput = input} onKeyPress={this.search.bind(this)} onChange={this.search.bind(this)}></InputText>
                    </InputContainer>
                </div>

                <ContainerTable>
                {/* <div> */}
                    <Table>
                        <thead>
                            <tr>
                                <td>Nome</td>
                                <td>Telefone</td>
                                <td>Endereço</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.users.length === 0 ?
                                <tr><td colSpan={3}>Nenhum usuário encontrado</td></tr>
                                :
                                this.state.users.map(user =>
                                    <tr key={user.id}>
                                        <td onClick={() => this.props.onUserSelection(user)}><span>{user.name}</span></td>
                                        <td onClick={() => this.props.onUserSelection(user)}><span>{user.phoneNumber}</span></td>
                                        <td>
                                            <span onClick={() => this.props.onUserSelection(user)}>{user.email}</span>
                                            <Editar onClick={ () => this.props.modal(user, this.search.bind(this)) }><img src={ Icon } alt="Editar"></img>Editar</Editar>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </ContainerTable>
                <div>
                    <Button transparent={true} width='300px' margin='24px 0 0' onClick={() => this.props.modal(null)}>CADASTRAR NOVO USUÁRIO</Button>
                </div>
            </Wrapper>
        )
    }
}
