import styled from 'styled-components'

export let TimerContainer = styled.div`
    border: 0.75px solid #979797;
    min-width: 75px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 8px;
    cursor: text;
    margin-left: 28px;

    svg{
        width: 16px;
        height: 16px;
        fill: #333;
    }

    input{
        width: 35px;
        height: 30px;
        border: 0;
        outline: none;
        color: #979797;
        font-size: 11px;
        font-family: 'ArialMT';
        margin-top: 1px;
        text-align: right;
    }
`

export let TimerPlaceholder = styled.span`
    color: #979797;
    font-size: 11px;
    font-family: 'ArialMT';
    margin-top: 1px;
    padding-left: 4px;
`