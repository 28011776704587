import React from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import Card from './Card'

const Loading = styled.p`
    font-family: Proxima,sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 5px;
`

const ColumnCard = styled.div`
    background-color: ${props => props.isDraggingOver ? 'rgba(216, 216, 216, 0.5)' : `transparent`};
    border-radius: 4px;
    padding: 0px;

    transition: 0.4s background-color;
`

export default class CardList extends React.Component {

  getOrdersByStatus(status) {
    if (this.props.orders) {
      if (this.props.id === "doing") {
        const ordersMobile = this.props.orders.filter(order => order.status === status && !!order.transactionId);
        const ordersStore = this.props.orders.filter(order => order.status === status && !order.transactionId);

        return [...ordersMobile, ...ordersStore]
      }
      
      return this.props.orders.filter(order => order.status === status);
    }
    return [];
  }


  render() {
    return (
      <Droppable droppableId={`${this.props.id}`}>
        {(provided, snapshot) => (
          <ColumnCard
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            className="list-orders"
            ref={provided.innerRef}
          >
            {this.props.orders === null ? (
              <Loading>Carregando...</Loading>
            ) : (
              this.getOrdersByStatus(this.props.id).map((order, index) => {
                const type = order.transactionId ? 'mobile' : 'store';

                if (this.props.type && this.props.type === type) {
                  return (
                    <Card
                      index={index}
                      status={order.status}
                      key={order.id}
                      id={order.id}
                      name={order.name}
                      onClickOrderDetails={() =>
                        this.props.onClickOrderDetails(order.id)
                      }
                      onClickMoveOrder={() =>
                        this.props.onClickMoveOrder(order.id, this.props.id)
                      }
                      isDragDisabled={this.props.isDragDisabled}
                      transactionId={order.transactionId}
                    />
                  )
                }
                if (!this.props.type) {
                  return (
                    <Card
                      index={index}
                      status={order.status}
                      key={order.id}
                      id={order.id}
                      name={order.name}
                      onClickOrderDetails={() =>
                        this.props.onClickOrderDetails(order.id)
                      }
                      onClickMoveOrder={() =>
                        this.props.onClickMoveOrder(order.id, this.props.id)
                      }
                      isDragDisabled={this.props.isDragDisabled}
                      transactionId={order.transactionId}
                    />
                  )
                }
                return <div/>
              }
              )
            )}
            {provided.placeholder}
          </ColumnCard>
        )}
      </Droppable>
    );
  }
}