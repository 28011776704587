/* eslint-disable no-unused-expressions */
import React from "react";
import styled from "styled-components";
import {ReactComponent as Next} from "../assets/svg/next-light.svg";
import Axios from "../Axios";
import Numeric from './Numeric'

const Container = styled.div`
  height: 100%;
  max-height: 27px;
  transition: max-height 0.5s;
  overflow: hidden;
  padding-top: 5px;

  &.opened {
    max-height: 2000px;
    transition: max-height 0.5s;
  }

  .header {
    display: inline-flex;
    margin-bottom: 8px;

    h4 {
      color: #525252;
      font-family: "ArialMT";
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
    }

    button {
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;

      svg {
        margin-left: 8px;
        margin-top: -2px;
        width: 18px;
        transform: rotate(90deg);
      }
    }
  }

  .condiments__list {
    .condiment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      color: #262524;
      font-family: "ArialMT";
      font-size: 12px;
      line-height: 16px;
    }

    input {
      width: 45px;
      height: 24px;
      border: 1px solid #838383;
      text-align: right;
      padding-right: 2px;

      &::-webkit-inner-spin-button {
        opacity: 1;
        margin-left: 3px;
      }
    }
  }
  .payment {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      color: #525252;
      font-family: "ArialMT";
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      font-family: "ArialMT";
      color: #525252;
      font-weight: 400;
    }
  }
`;

class Condiments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      price: "0.00",
      condimentsList: [],
    };

    this.toggle = this.toggle.bind(this);
    this.loadCondiments = this.loadCondiments.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
  }

  toggle() {
    if(!this.state.opened){
      this.updatePayment()
    }
    this.setState((current) => {
      return {opened: !current.opened};
    });
  }

  componentDidMount() {
    this.loadCondiments();
  }

  async loadCondiments() {
    await Axios.get("/spices").then((res) => {
      this.setState({condimentsList: res.data.rows});
    });
  }

  async updatePayment() {
    this.setState({price: 'Calculando...'});
    const data = this.getSpices();
    await Axios.post("/spices/calc", data).then((res) => {
      this.setState({price: `R$ ${parseFloat(res.data)
        .toFixed(2)
        .replace(".", ",")}`});
    });
  }

  async updateProduct(e) {
    e.preventDefault();
    this.props.onCondimentsUpdate(this.props.product, this.condiments);
  }

  getSpiceFromProduct(id) {
    return this.props.product.spices.filter((s) => {
      return s.spice.id === id;
    })[0];
  }

  getSpices() {
    const spices = [];
    const list = this.condiments.querySelectorAll(".condiment-input");

    for (let i = 0; i < list.length; i++) {
      spices.push({
        spice: parseInt(list[i].name),
        quantity: parseInt(list[i].value),
      });
    }

    return spices;
  }

  render() {
    return (
      <Container className={`condiments ${this.state.opened ? "opened" : ""}`}>
        <form onSubmit={(e) => this.updateProduct(e)} ref={(ref) => (this.condiments = ref)}>
          <div className="header">
            <h4>Condimentos</h4>
            <button type="button" onClick={this.toggle}>
              <Next fill="#F29448"></Next>
            </button>
          </div>
          <div className="condiments__list">
            {this.state.condimentsList.map((condiment, index) => {
              const spice = this.getSpiceFromProduct(condiment.id);
              return (
                <div className="condiment" key={index}>
                  <p>{condiment.name}</p>
                  {/* <input
                    min={0}
                    onChange={() => this.updatePayment()}
                    className="condiment-input number"
                    name={`${condiment.id}`}
                    type="number"
                    defaultValue={spice ? spice.quantity : 0}
                  /> */}
				  <Numeric min={0} marginRight="10px" className="condiment-input number" defaultValue={spice ? spice.quantity : 0} name={`${condiment.id}`} onChange={() => {}} />
                </div>
              );
            })}
          </div>
          <div className="payment">
            <h4>Cobrança</h4>
            <p>{this.state.price}</p>
          </div>
          <div
            className="actions"
            style={{marginTop: 5, justifyContent: "flex-end"}}
          >
            <button type="submit">Atualizar condimentos</button>
          </div>
        </form>
      </Container>
    );
  }
}

export default Condiments;
