import React from "react";
import styled from "styled-components";
import NewOrderBlock from "./NewOrderBlock";
import Axios from "../Axios.js";
import { InputText, InputContainer } from "./InputText";
import { DragDropContext } from "react-beautiful-dnd";
import ProductList from "./ProductList";

import getProductByID from "../helpers/getProductByID";

const WithoutProducts = styled.h3`
  color: #262524;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 11px;
  padding: 24px;
`;

class ProductListContainer extends React.Component {
  state = {
    products: [],
    active: null,
    category: null,
  };

  async getProductList() {
    let requestProducts = this?.props?.express
      ? "/products/express"
      : "/products";
    return await Axios.get(requestProducts).then((response) => {
      let products = this.props.all
        ? response.data.rows
        : response.data.rows.filter(
            (p) => p.category_id === this.props.category
          );
      window.localStorage.setItem("products", JSON.stringify(products));

      products = products.sort(function (a, b) {
        return a.position - b.position || a.name.localeCompare(b.name);
      });

      // this.updateAllProducts(products);

      this.setState({ products: products });
    });
  }

  updateAllProducts(products) {
    products.forEach((p, index) => {
      let product = {
        category_id: p.category_id,
        name: p.name,
        price: p.price,
        position: index,
      };

      this.updateProduct(p.id, product);
    });
  }

  async updateProduct(id, product) {
    await Axios.put(`/products/${id}`, product);
  }

  async searchProducts() {
    if (this.searchInput.value === "") {
      this.getProductList();
      return;
    }

    await Axios.get(`/products/find?search=${this.searchInput.value}`).then(
      (response) => {
        let products = this.props.all
          ? response.data.rows
          : response.data.rows.filter(
              (p) => p.category_id === this.props.category
            );
        window.localStorage.setItem("products", JSON.stringify(products));

        products = products.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({ products: products });
      }
    );
  }

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ active: this.props.active });
    }

    this.setState({ category: this.props.category, active: this.props.active });
    this.getProductList();
  }

  onProductClick(product) {
    this.setState({ active: product.id });
    this.props.handleProductClick(product);
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    this.setState({
      products: this.reorder(
        this.state.products,
        result.source.index,
        result.destination.index
      ),
    });

    setTimeout(() => {
      let id = parseInt(this.state.products[result.destination.index].id),
        position = result.destination.index;
      this.updateProductPosition(id, position);
    }, 1000);
  }

  async updateProductPosition(id, position) {
    let data = getProductByID(this.state.products, id);

    let product = {
      category_id: data.category_id,
      name: data.name,
      price: data.price,
      position: position,
    };

    await Axios.put(`/products/${id}/position`, {
      position: product.position,
    });
  }

  render() {
    return (
      <NewOrderBlock className={this.state.active ? `activeState` : ``}>
        <InputContainer className="search">
          <InputText
            ref={(input) => (this.searchInput = input)}
            onKeyPress={this.searchProducts.bind(this)}
            onChange={this.searchProducts.bind(this)}
            placeholder={"Filtrar produtos..."}
          ></InputText>
        </InputContainer>
        {this.state.products.length === 0 ? (
          <WithoutProducts>Nenhum produto nesta categoria</WithoutProducts>
        ) : (
          <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <ProductList
              products={this.state.products}
              id="product-list"
              active={this.state.active}
              edit={this.props.edit}
              onProductClick={this.onProductClick.bind(this)}
              isDropDisabled={
                !this.state.category ||
                this.state.active ||
                this.props.edit ||
                this.props.editSavedOrder
              }
            />
          </DragDropContext>
        )}
      </NewOrderBlock>
    );
  }
}

export default ProductListContainer;
