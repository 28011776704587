import React from 'react';
import styled from 'styled-components';
import { printProduct } from '../helpers/nfc';
import { Modal, ModalTitle } from './Modal';

import { NavLink } from "react-router-dom";


const ModalDetailsBlock = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    min-width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    text-align: center;

    h1{
        color: #414141;
        font-family: Arial;
        font-size: 26px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 30px;
    }

    h3{
        color: #414141;
        font-family: 'ArialMT';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 40px;
    }

    .btn{
        border-radius: 4px;
        width: 100%;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        padding: 17px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        background: #EEBA1A;
        color: #000;
        margin-bottom: 15px;
        cursor: pointer;
        border: none;

        &-transparent{
            border: 2px solid #D18B00;
            color: #D18B00;
            background: transparent;
        }
    }
`

const ModalContainer = styled.div`
    background-color: #fff;
    position: relative;
    display: flex;
    padding: 32px;
    max-width: 425px;
    width: 100%;
    border-radius: 4px;
    justify-content: center;

`


class ModalConfirmedOrder extends React.Component{
    onClickNew(){
        window.location.reload();
        this.props.close();
	}

	handleClick = (e) => {
		if (e.target !== e.currentTarget) return;
		this.props.close();
	};	

    render() {
        return(
            <Modal className="modal-order-details" onClick={this.handleClick}>
                <ModalContainer>
                    <ModalDetailsBlock>
                        <ModalTitle>Pedido #{this.props.id} foi criado!</ModalTitle>
                        <h3>O pedido foi confirmado e adicionado a lista de pedidos em andamento.</h3>

                        <NavLink className="btn btn-transparent" exact to={`/print/${this.props.id}`} target="_blank">IMPRIMIR PEDIDO</NavLink>
                        <button onClick={() => printProduct(this.props.id)} className="btn btn-transparent">IMPRIMIR NOTA FISCAL</button>
                        <button onClick={this.onClickNew} className="btn btn-transparent">CRIAR UM NOVO PEDIDO</button>
                        <NavLink className="btn btn-transparent" exact to="/">IR PARA A LISTA DE PEDIDOS</NavLink>
                    </ModalDetailsBlock>
                </ModalContainer>
            </Modal>
        )
    }
}

export default ModalConfirmedOrder;
