import Axios from "../Axios";

export const printProduct = async (orderId, onError) => {
	let response;
	try {
		response = await Axios.get(`/nota-fiscal/${orderId}`);
	} catch (e) {
		console.error(e);
	}
	if (response.status !== 200) {
		onError();
		return;
	}
	console.log('Enviando para impressora agora > ' + response.data.pdf)
	const blob = await fetch(response.data.pdf).then(pdf => pdf.blob())
	const dataUrl = window.URL.createObjectURL(blob);

	// Open the window
	const pdfWindow = window.open(dataUrl);
	if(pdfWindow){
		pdfWindow.print();
	}
}
