import React, { useState, useEffect, createContext, useContext } from 'react';
import Axios from '../Axios';

const StockContext = createContext({});


export function StockProvider({ children }) {
  const [breads, setBreads] = useState([]);
  const [isOrder, setIsOrder] = useState(false);

  useEffect(() => {
    Axios.get('/ingredients/breads/stock')
        .then(response => {
          setBreads(response.data);
        });
    setInterval(() => {
      Axios.get('/ingredients/breads/stock')
        .then(response => {
          setBreads(response.data);
        });
    }, 60 * 4 * 1000)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      Axios.get('/ingredients/breads/stock')
        .then(response => {
          setBreads(response.data);
        });
    }, 5000);
  }, [isOrder]);

  const updateBreads = (quantityValues) => {
    const updatedBreads = [...breads];

    breads.forEach((bread, index) => {
      if (bread.quantity !== quantityValues[bread.name]) {
        Axios.put(`/ingredients/${bread.id}`, {
          quantity: quantityValues[bread.name]
        });

        updatedBreads[index]['quantity'] = quantityValues[bread.name];
      }
    })
    setBreads(updatedBreads);
    alert('Quantidades atualizadas com sucesso');
  }

  return (
    <StockContext.Provider
      value={{
        breads,
        updateBreads,
        isOrder,
        setIsOrder
      }}
    >
      {children}
    </StockContext.Provider>
  )
}

export { StockContext };

export function useStock() {
  const context = useContext(StockContext);

  return context;
}