import React from 'react';
import styled from 'styled-components';
import { Modal, ModalClose } from './Modal';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import Axios from '../Axios';


const ModalDetailsBlock = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    min-width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    text-align: left;

    h2{
        color: #262524;
        font-family: Arial;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 24px;
    }

    .btn{
        border-radius: 4px;
        width: 100%;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        padding: 17px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        background: #EEBA1A;
        color: #000;
        cursor: pointer;
        border: none;
    }

    textarea{
        width: 361px;
        height: 149px;
        border: 1px solid #838383;
        resize: none;
        color: #838383;
        padding: 15px;
        font-family: 'ArialMT';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 24px;
    }
`

const ModalContainer = styled.div`
    background-color: #fff;
    position: relative;
    display: flex;
    padding: 32px;
    max-width: 425px;
    width: 100%;
    border-radius: 4px;

    p{
        color: #858585;
        font-family: 'ArialMT';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 14px;
    }
`


class ModalCancel extends React.Component {
  state = {
    shouldRenderMessage: false
  }

  cancelOrder() {
    let motive = document.querySelector("#order-cancel").value;
    let id = this.props.order.id;

    Axios.post(`/orders/${id}/move`, {
      status: 'cancelled',
      motive: motive
    }).then(res => {
      this.setState({ shouldRenderMessage: false }, () => {
        this.props.onCancel(id);
        this.props.close(id);
        alert(`Pedido ${id} cancelado!`);
      });
    });
  }


  onEnter(event) {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;
    const bullet = "\u2022";
    const bulletWithSpace = `${bullet} `;
    const enter = 13;

    if (keyCode === enter) {
      target.value = [...value]
        .map((c, i) => i === selectionStart - 1
          ? `\n${bulletWithSpace} `
          : c
        )
        .join('');

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
  }

  handleClick = (e) => {
	if (e.target !== e.currentTarget) return;
	this.props.close();
  };	

  render() {
    return (
      <Modal className="modal-order-details" onClick={this.handleClick}>
        <ModalContainer>
          <ModalClose href="#" onClick={() => this.props.close()}><Close></Close></ModalClose>
          <ModalDetailsBlock>
            <h2>Motivo do Cancelamento</h2>
            <textarea onKeyUp={e => this.onEnter(e)} id="order-cancel" placeholder="Digite aqui o motivo do cancelamento" ></textarea>
            <button className="btn" onClick={this.cancelOrder.bind(this)}>CANCELAR PEDIDO</button>
          </ModalDetailsBlock>
        </ModalContainer>
      </Modal>
    )
  }
}

export default ModalCancel;