import React from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import userService from '../helpers/userService';

import { Label, InputText, InputContainer } from '../components/InputText';
import { Button } from '../components/Button';
import Sanata from '../assets/svg/sanata-logo.jpg';


const GlobalStyles = createGlobalStyle`
    html, body{
        background-color: #262524;
    }
`

const LoginArea = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 90px);
    padding: 30px;

    @media (max-width: 480px){
        padding: 0;
        background-color: #21201f;
    }

    h1{
        color: #414141;
        font-family: Arial;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 24px;

        @media (max-width: 480px){
            display: none;
        }
    }

    p{
        color: #858585;
        font-family: 'ArialMT';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 32px;

        &.error-message{
            margin-bottom: 0;
            font-weight: 400;
        }
    }

    .sanata-logo{
        max-width: 250px;
        margin-bottom: 50px;
        max-height: 60px;
    }
`

const LoginContainer = styled.div`
    background-color: #fff;
    width: 100%;
    max-width: 384px;
    padding: 30px;
    border-radius: 4px;

    @media (max-width: 480px){
        border-radius: 16px 16px 0 0;
        height: 100vh;
    }

    .input-container{
        margin-bottom: 16px;
        input{
            border: 1px solid #979797;
        }
    }
`


class Login extends React.Component{

    state = {
        message : null
    }

    login(e){
        e.preventDefault();

        let user = { password: document.querySelector('#password').value, email: document.querySelector('#email').value }

        if(!user.password || !user.email){
            this.setState({message: 'Email ou senha em branco.'})
            return;
        }

        this.setState({message: null});

        let error = res => {
            if(res.response?.data)
                this.setState({message: res.response.data})
        },
        success = res => {
            if (res.data) {
                localStorage.setItem('token',res.data);
                window.location.reload();
            }
        }
        
        userService.login(user.email, user.password, success, error);
	}
	
	componentDidMount() {
		document.title = 'Login - Sanata Delivery'
	}

    render(){
        return(
            <React.Fragment>
                <GlobalStyles/>
                <LoginArea>
                    <img alt="sanata-logo" className="sanata-logo" src={Sanata}></img>
                    <LoginContainer>
                        <p>Olá! Entre com seu e-mail e senha.</p>
                        <form onSubmit={this.login.bind(this)} name="newAddress">
                            <InputContainer className="input-container">
                                <Label>Email</Label>
                                <InputText type="email" id="email" name="email"></InputText>
                            </InputContainer>

                            <InputContainer className="input-container">
                                <Label>Senha</Label>
                                <InputText type="password" id="password" name="password"></InputText>
                            </InputContainer>
                            {
                                this.state.message ? <p className="error-message">{this.state.message}</p> : ``
                            }
                            <Button onClick={this.login.bind(this)}>ENTRAR</Button>
                        </form>
                    </LoginContainer>
                </LoginArea>
            </React.Fragment>
        )
    }
}

export default Login;
