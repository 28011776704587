/* eslint-disable import/first */

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing'

Sentry.init({
	dsn: 'https://811df47892144dd9974f55b47b0a5bf4@o342625.ingest.sentry.io/5459012',
	integrations: [
		new Integrations.BrowserTracing()
	],
	tracesSampleRate: 1.0
})

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
