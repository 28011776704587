import React from 'react';
import styled from 'styled-components';
import variables from '../assets/styles/variables'

import { NavLink } from "react-router-dom";
import ReactInputMask from 'react-input-mask';

import { StockContext } from '../hooks/useStock';


import { TimerContainer, TimerPlaceholder } from './Timer';
import BreadCounter from './BreadCounter';
import { ReactComponent as Clock } from '../assets/svg/clock.svg';
import { ReactComponent as Hotdog } from '../assets/svg/hotdog.svg';
import { ReactComponent as Bread } from '../assets/svg/bread.svg';
import { ReactComponent as BreadJunior } from '../assets/svg/breadJunior.svg';

const MenuBlock = styled.nav`
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #262524;
    display: flex;
    font-family: ${variables.body.fontFamily};
    height: 54px;
    padding: 0 32px 0 32px;

    a{
        color: #838383;
        font-size: 17px;
        line-height: 24px;
        text-decoration: none;
        margin-right: 64px;
        height: 100%;
        display: inline-flex;
        align-items: center;

        &.active{
            box-shadow: inset 0 -6px 0 0 #262524;
            color: #262524;
        }
    }
`;

const BreadCounterContainer = styled.div`
    display: flex;
    margin-left: auto;

    div + div {
        margin-left: 14px; 
    }
`;

const pressedKeys = { tab: false, shiftleft: false, enter: false, space: false };

const highlightedTabStyle = {
    backgroundColor: '#f0f0f0',
    padding: '0px 10px'
}

class Menu extends React.Component {

    state = {
        shouldRenderInput: false,
        shouldRenderPlaceholder: true,
        timer: '40 min',
        timerDefaultValue: '00:40',
        highlightedTab: -1,
        // breads: [],
    }

    refsArray = [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()];

    componentDidMount() {
        if (window.localStorage.getItem('timer')) {
            this.setState({
                timer: window.localStorage.getItem('timer'),
                timerDefaultValue: window.localStorage.getItem('timerDefaultValue')
            })
        }
        onkeydown = onkeyup = (e) => {
            for (let input of document.querySelectorAll('input')) {
                if (input === document.activeElement) return;
            }
            pressedKeys[e.code.toLowerCase()] = e.type === 'keydown';
            if (pressedKeys.tab && pressedKeys.shiftleft) {
                e.preventDefault();
                if (this.state.highlightedTab === 0) this.setState({ highlightedTab: 3 });
                else if (this.state.highlightedTab < 0) this.setState({ highlightedTab: 0 });
                else this.setState({ highlightedTab: this.state.highlightedTab - 1 });
            } else if (pressedKeys.tab) {
                e.preventDefault();
                if (this.state.highlightedTab === 3) this.setState({ highlightedTab: 0 });
                else if (this.state.highlightedTab < 0) this.setState({ highlightedTab: 0 });
                else this.setState({ highlightedTab: this.state.highlightedTab + 1 });
            } else if (pressedKeys.enter || pressedKeys.space) {
                this.refsArray[this.state.highlightedTab].current.click()
            }
        }
    }

    toggleInput(value) {
        this.setState({
            shouldRenderInput: !this.state.shouldRenderInput,
            shouldRenderPlaceholder: !this.state.shouldRenderPlaceholder
        }, () => {
            if (this.state.shouldRenderInput)
                this.input.focus();

            if (!value) return;


            let hour = value.split(':')[0];
            let min = value.split(':')[1];

            hour = parseInt(hour) > 0 ? `${hour} h` : ``;
            min = `${min} min`;

            this.setState({ timer: `${hour} ${min}`, timerDefaultValue: value })
            window.localStorage.setItem('timer', `${hour} ${min}`);
            window.localStorage.setItem('timerDefaultValue', value);

        });
    }

    render() {
        return (
            <MenuBlock>
                <NavLink exact to="/" ref={this.refsArray[0]} activeClassName="active" style={this.state.highlightedTab === 0 ? highlightedTabStyle : undefined}>Pedidos</NavLink>
                <NavLink to="/cardapio" ref={this.refsArray[2]} activeClassName="active" style={this.state.highlightedTab === 2 ? highlightedTabStyle : undefined}>Cardápio</NavLink>
                <NavLink exact to="/clients" ref={this.refsArray[3]} activeClassName="active" style={this.state.highlightedTab === 3 ? highlightedTabStyle : undefined}>Clientes</NavLink>
                <NavLink exact to="/relatorio" ref={this.refsArray[4]} activeClassName="active" style={this.state.highlightedTab === 4 ? highlightedTabStyle : undefined}>Relatório</NavLink>
                <NavLink exact to="/estoque" ref={this.refsArray[5]} activeClassName="active" style={this.state.highlightedTab === 5 ? highlightedTabStyle : undefined}>Estoque</NavLink>
                {this.props.editSavedOrder ? <NavLink to="/pedido" activeClassName="active">Editar Pedido</NavLink> : ``}

                <BreadCounterContainer>
                    {this.context.breads.map(bread => {
                        let Icon;
                        if (bread.name.toLowerCase().match("hotdog")) {
                            Icon = Hotdog;
                        } else if (bread.name.toLowerCase().match("senior")) {
                            Icon = Bread;
                        } else if (bread.name.toLowerCase().match("junior")) {
                            Icon = BreadJunior;
                        }

                        let status = "";
                        if (bread.quantity <= 50) status = 'critical';
                        else if (bread.quantity <= 100) status = 'alert';
                        else status = 'great';

                        return (
                            <BreadCounter
                                status={status}
                                key={bread.id}
                                icon={Icon}
                                amount={bread.quantity}
                            />
                        )
                    })}
                </BreadCounterContainer>

                <TimerContainer onClick={() => this.toggleInput()}>
                    <Clock></Clock>
                    {this.state.shouldRenderPlaceholder ?
                        <TimerPlaceholder id="inputTimer" data-time={this.state.timer}>{this.state.timer}</TimerPlaceholder>
                        : ``
                    }
                    {
                        this.state.shouldRenderInput ? <ReactInputMask ref={(input) => { this.input = input; }} onBlur={(e) => this.toggleInput(e.currentTarget.value)} alwaysShowMask={true} mask="99:99" defaultValue={this.state.timerDefaultValue}></ReactInputMask> : ``
                    }
                </TimerContainer>
            </MenuBlock>
        )
    }
}


Menu.contextType = StockContext;
export default Menu;