import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Axios from "../Axios";
import NumberFormat from "react-number-format";
import { format, isAfter, parseISO } from "date-fns";
import Pagination from "@material-ui/lab/Pagination";

import useDebounce from "../hooks/useDebounce";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Table from "../components/Table";
import Select from "../components/Select";

import formatCurrency from "../helpers/formatCurrency";
import dateIsValid from "../helpers/dateIsValid";

import SearchIcon from "../assets/svg/Search.svg";
import Calendar from "../assets/svg/calendar.svg";
import ArrowIcon from "../assets/svg/arrow.svg";
import { InputText, Label } from "../components/InputText";

const Report = () => {
  const [isTableOpened, setIsTableOpened] = useState(true);
  const [isReportsShow, setIsReportsShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [itemSelected, setItemSelected] = useState("Todos");
  const [category, setCategory] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [dateValues, setDateValues] = useState({
    initialDate: format(new Date(), "dd/MM/yyyy"),
    finalDate: format(new Date(), "dd/MM/yyyy"),
  });
  const [previousDateValues, setPreviousDateValues] = useState({});
  const [previousCategory, setPreviousCategory] = useState(0);
  const [previousSearch, setPreviousSearch] = useState("");
  const [dataCSV, setDataCSV] = useState("");

  const [total, setTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const debouncedSearchFilter = useDebounce(searchFilter, 500);

  useEffect(() => {
    if (
      dateValues.initialDate?.trim().length === 10 &&
      dateValues.finalDate?.trim().length === 10
    ) {
      try {
        const initialDate = format(
          new Date(
            dateValues.initialDate
              .split("/")
              .reverse()
              .join("-")
              .concat("T22:59:59")
          ),
          "yyyy-MM-dd"
        );
        const finalDate = format(
          new Date(
            dateValues.finalDate
              .split("/")
              .reverse()
              .join("-")
              .concat("T23:59:59")
          ),
          "yyyy-MM-dd"
        );

        if (
          !dateIsValid(dateValues.initialDate) ||
          !dateIsValid(dateValues.finalDate)
        ) {
          throw new Error();
        }

        if (
          isAfter(parseISO(initialDate), parseISO(finalDate)) ||
          isAfter(parseISO(finalDate), new Date())
        ) {
          alert("Intervalo de data inválido");
          return;
        }

        Axios.post("/report/filter", {
          start: initialDate,
          end: finalDate,
          limit: limit,
          offset: offset,
          search: debouncedSearchFilter,
          category: category,
        }).then((response) => {
          setOrders(response.data);
          const totalItems = response.headers["total-orders"] || 0;
          setTotalPrice(response.headers["total-price"] || 0);
          setTotalProduct(response.headers["total-item-quantity"] || 0);
          setTotalProductPrice(response.headers["total-item-price"] || 0);
          setTotal(totalItems);
          setTotalPages(Math.ceil(totalItems / limit));
        });

        if (
          previousDateValues.initialDate !== dateValues.initialDate ||
          previousDateValues.finalDate !== dateValues.finalDate ||
          previousCategory !== category ||
          previousSearch !== debouncedSearchFilter
        ) {
          setPage(1);
          setOffset(0);
          setPreviousDateValues({ ...dateValues });
          setPreviousCategory(category);
          setPreviousSearch(debouncedSearchFilter);
        }
      } catch (error) {
        alert("Data inválida");
        return;
      }
    } else {
      if (
        dateValues.initialDate?.trim().length === 0 &&
        dateValues.finalDate?.trim().length === 0
      ) {
        Axios.post("/report/filter", {
          limit: limit,
          offset: offset,
          search: debouncedSearchFilter,
          category: category,
        }).then((response) => {
          setOrders(response.data);
          const totalItems = response.headers["total-orders"] || 0;
          setTotalPrice(response.headers["total-price"] || 0);
          setTotalProduct(response.headers["total-item-quantity"] || 0);
          setTotalProductPrice(response.headers["total-item-price"] || 0);
          setTotal(totalItems);
          setTotalPages(Math.ceil(totalItems / limit));
        });
        if (
          previousDateValues.initialDate !== dateValues.initialDate ||
          previousDateValues.finalDate !== dateValues.finalDate ||
          previousCategory !== category ||
          previousSearch !== debouncedSearchFilter
        ) {
          setPage(1);
          setOffset(0);
          setPreviousDateValues({ ...dateValues });
          setPreviousCategory(category);
          setPreviousSearch(debouncedSearchFilter);
        }
      }
    }
  }, [
    page,
    offset,
    limit,
    dateValues.initialDate,
    dateValues.finalDate,
    previousDateValues.initialDate,
    previousDateValues.finalDate,
    dateValues,
    debouncedSearchFilter,
    category,
    previousCategory,
    previousSearch,
  ]);

  useEffect(() => {
    if (dataCSV) {
      let csvContent;

      if (totalProductPrice === 0) {
        csvContent = dataCSV
          .replace(/;/g, "/")
          .replace(/,/g, ";")
          .replace(/\./g, ",")
          .concat(`Valor total, ${formatCurrency(totalPrice)}`);
      } else {
        csvContent = dataCSV
          .replace(/;/g, "/")
          .replace(/,/g, ";")
          .replace(/\./g, ",").concat(`Valor total; ${formatCurrency(
          totalPrice
        )}; 
                  Valor total da busca; ${formatCurrency(totalProductPrice)};
                  Total de itens; ${totalProduct}`);
      }

      const blobData = new Blob(["\ufeff" + csvContent], {
        type: "application/vnd.ms-excel",
      });
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Relatório";
      a.click();

      setDataCSV("");
    }
  }, [dataCSV, totalPrice, totalProduct, totalProductPrice]);

  const exportReport = () => {
    if (
      dateValues.initialDate?.trim().length === 10 &&
      dateValues.finalDate?.trim().length === 10
    ) {
      const initialDate = format(
        new Date(
          dateValues.initialDate.split("/").reverse().map(Number).join("-")
        ),
        "yyyy-MM-dd"
      );
      const finalDate = format(
        new Date(
          dateValues.finalDate.split("/").reverse().map(Number).join("-")
        ),
        "yyyy-MM-dd"
      );

      Axios.post("/report/download", {
        start: initialDate,
        end: finalDate,
        search: debouncedSearchFilter,
        category: category,
      }).then((response) => {
        setDataCSV(response.data);
      });
    } else {
      Axios.post("/report/download", {
        search: debouncedSearchFilter,
        category: category,
      }).then((response) => {
        setDataCSV(
          response.data.concat(`Valor total, ${formatCurrency(totalPrice)}`)
        );
      });
    }
  };

  const toggleTable = () => {
    setIsTableOpened(!isTableOpened);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDateValues({ ...dateValues, [name]: value });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    const newOffset = (value - 1) * limit;
    setOffset(newOffset);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setItemSelected(value);

    if (value === "Todos") setCategory(0);
    else if (value === "Lanches") setCategory(1);
    else if (value === "Porções") setCategory(2);
    else if (value === "Bebidas") setCategory(3);
  };

  return (
    <>
      <Header />
      <Menu />
      <Container isTableOpened={isTableOpened}>
        {!isReportsShow && (
          <InputsContainer>
            <PasswordContainer>
              <Label>Senha de acesso: </Label>
              <InputText
                onKeyUp={(e) => {
                  let { value } = e.target;
                  value.toLowerCase() === "cf1984" && setIsReportsShow(true);
                }}
                type="password"
                name="password-reports"
              />
            </PasswordContainer>
          </InputsContainer>
        )}
        {isReportsShow && (
          <>
            <InputsContainer>
              <DateContainer>
                <img src={Calendar} alt="Ícone de calendário" />

                <span>De</span>
                <NumberFormat
                  format="##/##/####"
                  placeholder="__/__/____"
                  name="initialDate"
                  onChange={handleInputChange}
                  value={dateValues.initialDate || ""}
                />

                <span>Até</span>
                <NumberFormat
                  format="##/##/####"
                  placeholder="__/__/____"
                  name="finalDate"
                  onChange={handleInputChange}
                  value={dateValues.finalDate || ""}
                />
              </DateContainer>
              <Select
                onChange={handleSelectChange}
                value={itemSelected}
                options={["Todos", "Lanches", "Porções", "Bebidas"]}
                name="pedidos"
              />
              <SearchInput>
                <label htmlFor="search">
                  <img src={SearchIcon} alt="Buscar pedido" />
                </label>
                <input
                  id="search"
                  placeholder={
                    itemSelected === "Todos"
                      ? "Buscar"
                      : `Buscar em ${itemSelected}`
                  }
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                />
              </SearchInput>
            </InputsContainer>
            <TableTitleContainer>
              <TableTitle>
                <img
                  style={isTableOpened ? {} : { transform: "rotate(-90deg)" }}
                  onClick={toggleTable}
                  src={ArrowIcon}
                  alt="Seta"
                />
                <h2>Histórico de pedidos concluídos</h2>
              </TableTitle>
              {isTableOpened && totalProduct === 0 && (
                <strong>{total} Pedidos</strong>
              )}
              {isTableOpened && totalProduct !== 0 && (
                <div>
                  <strong>
                    {total} Pedidos ( {totalProduct} itens pesquisados )
                  </strong>
                </div>
              )}
            </TableTitleContainer>
            {isTableOpened && (
              <>
                <Table data={orders} />
                <Footer>
                  <button onClick={exportReport}>Exportar relatório</button>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    shape="rounded"
                    size="medium"
                  />
                  <PricesContainer>
                    {totalProductPrice !== 0 && (
                      <strong>
                        Valor total da busca:{" "}
                        {formatCurrency(totalProductPrice)}
                      </strong>
                    )}
                    <strong>Valor total: {formatCurrency(totalPrice)}</strong>
                  </PricesContainer>
                </Footer>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.main`
  ${(props) =>
    props.isTableOpened
      ? css`
          height: 100%;
        `
      : css`
          height: 100vh;
        `}
  width: 100%;
  background: #fafafa;
  padding: 13px 36px;
`;

const InputsContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  .select {
    width: 230px;
    margin: 0 10px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 7px;
  }

  span {
    font-size: 16px;
    color: #21201f;
    margin-left: 7px;
  }

  input {
    padding: 8px 16px;
    border: 1.5px solid #21201f;
    border-radius: 3px;

    margin-left: 7px;

    width: 120px;
    height: 42px;
  }
`;

const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 640px;

  label {
    display: contents;
  }

  span {
    font-size: 16px;
    color: #21201f;
    margin-left: 7px;
  }

  input {
    padding: 8px 16px;
    border: 1.5px solid #21201f;
    border-radius: 3px;

    margin-left: 7px;

    width: 80%;
    height: 42px;
  }
`;

const SearchInput = styled.div`
  height: 42px;
  width: 336px;

  padding: 13px;
  margin-left: 14px;

  display: flex;
  align-items: center;

  background: #ffffff;

  border-radius: 3px;
  border: 1.5px solid #21201f;

  input {
    width: 100%;

    font-size: 16px;

    background: #ffffff;
    color: #21201f;

    border: none;

    margin-left: 13px;
  }
`;

const TableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    padding-left: 8px;
    text-align: right;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
  }
`;

const TableTitle = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    cursor: pointer;
  }

  h2 {
    font-family: "ArialMT";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    color: #21201f;

    margin-left: 7px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  button {
    border: none;
    border-radius: 4px;
    background: #d18b00;
    color: white;
    padding: 9px 29px;
    font-family: Proxima;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    cursor: pointer;
  }

  strong {
    font-weight: 700;
    font-size: 18px;
  }

  .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-rounded.Mui-selected {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 2px;
`;

export default Report;
