import React from 'react';
import styled from 'styled-components';
import { Modal, ModalContainer, ModalTitle, ModalClose } from './Modal';
import { Label, InputText, InputContainer } from './InputText';
import { Button } from './Button';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import Axios from '../Axios';
import ExcluirImg from '../assets/svg/excluir.png';

const ModalAddressBlock = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    width: 941px;

    h1{
        margin-bottom: 24px;
    }

    .error{
        color: #D1004D;
    }
`;

const Exclude = styled.button`
    color: #D1004D;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    text-align: left;
    background: transparent;
    border: none;
    display: inline-flex;
    align-items: center;

    cursor: pointer;
    padding: 0;

    img{
        margin-top: -2px;
    }
`;

class ModalCreateUser extends React.Component {
    state = {
        error: false,
        address: null
    }

    componentDidMount() {
        if (this.props.user && this.props.user.address_id) {
            this.getAddress();
        }
    }

    async getAddress() {
        await Axios.get(`/addresses/${parseInt(this.props.user.address_id)}`)
            .then(req => {
                let obj = req.data;
                if (obj)
                    this.setState({ addressObj: obj });
            });
    }

    async onClickEdit() {
        let user = {
            "email": this.form.email.value,
            "name": this.form.name.value,
            "phoneNumber": this.form.phone.value,
            "password": "1234",
        };

        if (this.form.streetName.value) {
            let address = {
                "name": this.form.name.value,
                "streetName": this.form.streetName.value,
                "streetNumber": this.form.streetNumber.value,
                "complement": this.form.complement.value,
                "cep": this.form.cep.value
            }

            Axios.post('/addresses', address)
                .then(req => {
                    let obj = req.data;
                    user.address_id = obj.id;

                    if (!this.state.error) {
                        this.editUser(user);
                    }
                }).catch(req => {
                    if (req.response && req.response.status === 400)
                        this.setState({ error: req.response.data })
                })

            return;
        }

        if (this.state.address)
            user.address_id = this.state.address;

        if (!this.state.error) {
            this.editUser(user);
        }

    }

    onClickSave() {
        let user = {
            "email": this.form.email.value ? this.form.email.value : null,
            "name": this.form.name.value ? this.form.name.value : null,
            "phoneNumber": this.form.phone.value ? this.form.phone.value : null,
        };

        for (var key of Object.keys(user)) {
			if (!user[key]) delete user[key];
        }

        if (this.form.streetName.value !== "" && this.form.cep.value !== "" && this.form.streetNumber.value !== "") {
            let address = {
                "name": this.form.name.value ? this.form.name.value : "",
                "streetName": this.form.streetName.value ? this.form.streetName.value : "",
                "streetNumber": this.form.streetNumber.value ? this.form.streetNumber.value : "",
                "complement": this.form.complement.value ? this.form.complement.value : "",
                "cep": this.form.cep.value ? this.form.cep.value : "",
            }
            Axios.post('/addresses', address)
                    .then(req => {
                        let obj = req.data;
                        obj.id ? user.address_id = obj.id : user.address_id = null
                        this.saveUser(user);
                    }).catch(req => {
                        if (req.response && req.response.status === 400)
                            this.setState({ error: req.response.data })
                    })
        }

        if (!this.form.streetName.value && !this.form.cep.value && !this.form.streetNumber.value) {
            this.saveUser(user);
        } else {
            this.setState({ error: "Preencha os campos corretamente!" })
        }
    }


    onClickDelete() {
        if (window.confirm('Deseja deletar os dados do cliente ' + this.props.user.name + '?')) {
            this.deleteUser();
        }
    }

    async deleteUser() {
        await Axios.delete('profiles/' + this.props.user.id).then(() => window.location.reload());
    }

    async editUser(user) {
        await Axios.put(`/profiles/${this.props.user.id}`, user)
            .then(req => {
                user.id = req.data.id;
                this.props.save(user, false);
                window.location.reload();
            }).catch(req => {
                if (req.response && req.response.status === 400)
                    this.setState({ error: req.response.data })
            })
    }

    async saveUser(user) {
        await Axios.post('/auth/register', user)
            .then(req => {
                user.id = req.data.id;
                this.props.save(user, true);
            }).catch(req => {
                if (req.response && req.response.status === 400)
                    this.setState({ error: req.response.data })
            })
	}
	
	handleClick = (e) => {
		if (e.target !== e.currentTarget) return;
		this.props.close();
	};	

    render() {
        let user = this.props.user ? this.props.user : null;
        let addressObj = this.state.addressObj;
        return (
            <Modal className onClick={this.handleClick}>
                <ModalContainer>
                    <ModalClose href="#" onClick={() => this.props.close()}><Close></Close></ModalClose>
                    <ModalAddressBlock>
                        <ModalTitle>Cadastrar Cliente</ModalTitle>
                        <form name="newAddress" ref={form => this.form = form}>
                            <InputContainer className="half">
                                <InputContainer className="half-container">
                                    <Label>Nome completo</Label>
                                    <InputText required name="name" defaultValue={user ? user.name : ''}></InputText>
                                </InputContainer>

                                <InputContainer className="half-container">
                                    <Label>CEP</Label>
                                    <InputText name="cep" defaultValue={addressObj ? addressObj.cep : ''}></InputText>
                                </InputContainer>
                            </InputContainer>

                            <InputContainer className="half">
                                <InputContainer className="half-container">
                                    <Label>Rua</Label>
                                    <InputText name="streetName" defaultValue={addressObj ? addressObj.streetName : ''}></InputText>
                                </InputContainer>
                                <InputContainer className="half-container" style={{ marginTop: 0 }}>
                                    <InputContainer className="half" style={{ marginTop: 0 }}>
                                        <InputContainer className="half-container">
                                            <Label>Número</Label>
                                            <InputText name="streetNumber" defaultValue={addressObj ? addressObj.streetNumber : ''}></InputText>
                                        </InputContainer>
                                        <InputContainer className="half-container">
                                            <Label>Complemento</Label>
                                            <InputText name="complement" defaultValue={addressObj ? addressObj.complement : ''}></InputText>
                                        </InputContainer>
                                    </InputContainer>
                                </InputContainer>
                            </InputContainer>

                            <InputContainer className="half">
                                <InputContainer className="half-container" style={ { marginTop: 0 } }>
                                    <Label>Email</Label>
                                    <InputText name="email" defaultValue={user ? user.email : ''}></InputText>
                                </InputContainer>
                                <InputContainer className="half-container" style={ { marginTop: 0 } }>
                                    <Label>Telefone</Label>
                                    <InputText type="number" name="phone" defaultValue={user ? user.phoneNumber : ''}></InputText>
                                </InputContainer>
                            </InputContainer>
                        </form>

                        {this.state.error ? <p className="error">{this.state.error}</p> : ``}
                        { user ? <Exclude onClick={ this.onClickDelete.bind(this) }><img src={ ExcluirImg } alt="Excluir"></img>Excluir dados do cliente</Exclude> : '' }
                        {
                            user ?
                                <Button onClick={this.onClickEdit.bind(this)} width='300px' style={{ float: 'right' }}>CONFIRMAR</Button> :
                                <Button onClick={this.onClickSave.bind(this)} width='300px' style={{ float: 'right' }}>CONFIRMAR CADASTRO</Button>
                        }
                    </ModalAddressBlock>
                </ModalContainer>
            </Modal>
        )
    }
}

export default ModalCreateUser;
