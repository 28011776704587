import styled from 'styled-components';

const NewOrderBlock = styled.div`
    display: block;
    width: 100%;
    height: calc(100% - 130px);
    background-color: #fff;
    border-radius: 3px;
    overflow: auto;

    &.activeState{
        .inactive{
            opacity: 0.4;
        }
    }
`

export default NewOrderBlock;