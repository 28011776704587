import React from 'react';
import styled from 'styled-components';
import {Modal, ModalContainer, ModalTitle, ModalClose} from './Modal';
import {Label, InputText, InputContainer} from './InputText';
import {Button} from './Button';
import {ReactComponent as Close } from '../assets/svg/close.svg';
import Axios from '../Axios';

const ModalAddressBlock = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    width: 425px;

    h1{
        margin-bottom: 24px;
    }

    .error{

    }
`

class ModalAddress extends React.Component{
    state = {
        error : false
    }

    async saveAddress(){
        let form = document.querySelector('form[name="newAddress"]');
        let obj = {
            name : form.querySelector('[name="address-name"]').value,
            cep : form.querySelector('[name="address-cep"]').value,
            streetName : form.querySelector('[name="address-street"]').value,
            complement : form.querySelector('[name="address-complement"]').value,
            streetNumber : form.querySelector('[name="address-number"]').value
        }

        await Axios.post('/addresses', obj)
            .then(req => {
                this.setState({error:false})
                obj = req.data;

                window.localStorage.setItem('address', JSON.stringify(obj))

                this.props.onCloseModalAddress(obj)
            }).catch(req => {
                if(req.response && req.response.status === 400)
                    this.setState({error: req.response.data})
            })
	}
	
	handleClick = (e) => {
		if (e.target !== e.currentTarget) return;
		this.props.onCloseModalAddress();
	};

    render(){
        return(
            <Modal className onClick={this.handleClick}>
                <ModalContainer>
                    <ModalClose href="#" onClick={this.props.onCloseModalAddress}><Close></Close></ModalClose>
                    <ModalAddressBlock>
                        <ModalTitle>{ this.props.edit ? `Editar` : `Adicionar` } endereço de entrega</ModalTitle>
                        <form name="newAddress">
                            <InputContainer>
                                <Label>Nome</Label>
                                <InputText defaultValue={
                                    this.props.address ? this.props.address.name : 
                                    window.localStorage.getItem('address') ?  JSON.parse(window.localStorage.getItem('address')).name : ''} name="address-name"></InputText>
                            </InputContainer>

                            <InputContainer>
                                <Label>CEP</Label>
                                <InputText defaultValue={
                                    this.props.address ? this.props.address.cep : 
                                    window.localStorage.getItem('address') ?  JSON.parse(window.localStorage.getItem('address')).cep : ''} name="address-cep"></InputText>
                            </InputContainer>

                            <InputContainer>
                                <Label>Rua</Label>
                                <InputText defaultValue={
                                    this.props.address ? this.props.address.streetName : 
                                    window.localStorage.getItem('address') ?  JSON.parse(window.localStorage.getItem('address')).streetName : ''} name="address-street"></InputText>
                            </InputContainer>

                            <InputContainer className="half">
                                <InputContainer className="half-container">
                                    <Label>Número</Label>
                                    <InputText defaultValue={
                                        this.props.address ? this.props.address.streetNumber : 
                                        window.localStorage.getItem('address') ? JSON.parse(window.localStorage.getItem('address')).streetNumber : ''} name="address-number"></InputText>
                                </InputContainer>
                                <InputContainer className="half-container">
                                    <Label>Complemento</Label>
                                    <InputText defaultValue={
                                        this.props.address ? this.props.address.complement : 
                                        window.localStorage.getItem('address') ?  JSON.parse(window.localStorage.getItem('address')).complement : ''} name="address-complement"></InputText>
                                </InputContainer>
                            </InputContainer>
                        </form>

                        {this.state.error !== false ? <p className="error">{this.state.error}</p> : ``}
                        
                        <Button margin='24px 0 0 0' onClick={this.saveAddress.bind(this)}>{this.props.edit ? `EDITAR` : `ADICIONAR` } ENDEREÇO</Button>
                    </ModalAddressBlock>
                </ModalContainer>
            </Modal>
        )   
    }
}

export default ModalAddress;